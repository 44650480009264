import { useState, useContext, useEffect } from "react";
import {
  Tabs,
  Tab,
  Box,
} from "@mui/material";
import UserContext from "../../../../Data/UserContext";
import Day from "./Day";
import TotalSummary from "./TotalSummary";
import AssetContext from "../../../../Data/AssetsContext";
import QuestionsDrawer from "../QuestionsDrawer";
import { FlashOn } from "@mui/icons-material";
import FoodComplements  from "./FoodComplements";

export default function DaysList() {
  const { member } = useContext(UserContext);
  const { ingredients } = useContext(AssetContext);
  const joursDeLaSemaine = [
    { key: 0, title: "Lundi" },
    { key: 1, title: "Mardi" },
    { key: 2, title: "Mercredi" },
    { key: 3, title: "Jeudi" },
    { key: 4, title: "Vendredi" },
    { key: 5, title: "Samedi" },
    { key: 6, title: "Dimanche" },
  ];

  const [selectedTab, setSelectedTab] = useState(0);
  const [glucides, setGlucides] = useState(0);
  const [proteines, setProteines] = useState(0);
  const [lipides, setLipides] = useState(0);
  useEffect(() => {
    const days = member.Program.Days;
    let glu = 0;
    let prot = 0;
    let lip = 0;
    days.forEach((day) => {
      day.Meals.forEach((meal) => {
        meal.MealItems.forEach((mealItem) => {
          const ingredient = ingredients.find(
            (i) => i.Soid === mealItem.FoodSoid
          );
          if (ingredient) {
            glu += ingredient?.Glucides * mealItem.Quantity;
            prot += ingredient?.Proteines * mealItem.Quantity;
            lip += ingredient?.Lipides * mealItem.Quantity;
          }
        });
      });
    });
    setGlucides(glu / 7);
    setLipides(lip / 7);
    setProteines(prot / 7);
  }, [member, ingredients]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };



  return (
    <Box>
      <Box>
        <TotalSummary
          glucides={glucides}
          lipides={lipides}
          proteines={proteines}
          global
        />
        <Tabs value={selectedTab} onChange={handleChange} variant="scrollable">
          <Tab key={"Tab-7"} value={7} label={<FlashOn />} />
          {joursDeLaSemaine.map((jour) => (
            <Tab
              key={"Tab-" + jour.key.toString()}
              value={jour.key}
              label={jour.title}
            />
          ))}
        </Tabs>
      </Box>
      {member.Program.Days.map((day, index) => (
        <Day key={day.Soid} day={day} isVisible={index === selectedTab} />
      ))}
      <FoodComplements
        isVisible={selectedTab === 7}
      />
      <QuestionsDrawer questions={member.Survey.FoodQuestions} />
    </Box>
  );
}
