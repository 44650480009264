import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import AxiosContext from "../../../../Data/AxiosContext";
import { IUser } from "../../../../Data/Member";
import Select from "react-select";
import UserContext from "../../../../Data/UserContext";

export function PopupCopyMeal({
  daySoid,
  setDaySoid,
}: {
  daySoid: string | null;
  setDaySoid: any;
}) {
  const { axiosHelper } = useContext(AxiosContext);
  const { member, setMember } = useContext(UserContext);
  const [memberList, setMemberList] = useState<IUser[]>([]);
  const [selectedMemberSoid, setSelectedMemberSoid] = useState("");
  const [value, setValue] = React.useState("0");

  const [membersOptions, setMembersOptions] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  useEffect(() => {
    axiosHelper("/api/Scheduler/Members/MemberList").then((result) => {
      setMemberList(result.data);
    });
  }, [axiosHelper]);

  useEffect(() => {
    const tempOptions: any = [];
    if (memberList) {
      memberList.forEach((memberItem) => {
        let option: any = {};
        option.value = memberItem.Soid;
        option.label = `${memberItem.FirstName} ${memberItem.LastName}`;
        tempOptions.push(option);
      });
    }
    setMembersOptions(tempOptions);
  }, [memberList]);

  const handleClose = () => {
    setSelectedMemberSoid("");
    setDaySoid(null);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const copy = () => {
    axiosHelper
      .post(
        `api/Scheduler/Member/${member.Soid}/Day/${daySoid}/CopyDayFrom/${selectedMemberSoid}/Day/${value}`
      )
      .then((result) => {
        const updatedMember = {
          ...member, 
          Program: result.data,
        };
        setMember(updatedMember);
        setSelectedMemberSoid("");
        setDaySoid(null);
      });
  };

  return (
    <Dialog
      open={daySoid != null}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Copier le jour depuis un autre membre"}
      </DialogTitle>
      <DialogContent sx={{ minHeight: "250px" }}>
        <Grid container>
          <Grid item xs={5}>
            <Select
              options={membersOptions}
              onChange={(e: any) => {
                setSelectedMemberSoid(e.value);
              }}
            />
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={5}>
            {selectedMemberSoid && (
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={value}
                onChange={handleChange}
                name="radio-buttons-group"
              >
                <FormControlLabel value="0" control={<Radio />} label="Lundi" />
                <FormControlLabel value="1" control={<Radio />} label="Mardi" />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="Mercredi"
                />
                <FormControlLabel value="3" control={<Radio />} label="Jeudi" />
                <FormControlLabel
                  value="4"
                  control={<Radio />}
                  label="Vendredi"
                />
                <FormControlLabel
                  value="5"
                  control={<Radio />}
                  label="Samedi"
                />
                <FormControlLabel
                  value="6"
                  control={<Radio />}
                  label="Dimanche"
                />
              </RadioGroup>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Annuler</Button>
        <Button onClick={copy} autoFocus>
          Copier !
        </Button>
      </DialogActions>
    </Dialog>
  );
}
