import { Box, Typography } from "@mui/material";
import { IProgram } from "../../../../Data/Member";

export default function Bilan({ program }: { program: IProgram }) {
  return (
    <Box>
      <Box p={1}>
        <Typography sx={{ fontWeight: "bold", textDecoration: "underline" }}>
          Poids de la semaine:
        </Typography>
        <Typography>{program.Weight}</Typography>
      </Box>
      <Box p={1}>
        <Typography sx={{ fontWeight: "bold", textDecoration: "underline" }}>
          Douleurs:
        </Typography>
        <Typography>{program.Pain}</Typography>
      </Box>
      <Box p={1}>
        <Typography sx={{ fontWeight: "bold", textDecoration: "underline" }}>
          Plaisir Ressenti:
        </Typography>
        <Typography>{program.Fun}</Typography>
      </Box>
      <Box p={1}>
        <Typography sx={{ fontWeight: "bold", textDecoration: "underline" }}>
          Souhaits pour la semaine prochaine:
        </Typography>
        <Typography>{program.WishesForNextWeek}</Typography>
      </Box>
      <Box p={1}>
        <Typography sx={{ fontWeight: "bold", textDecoration: "underline" }}>
          Observations Diverses:
        </Typography>
        <Typography>{program.Notes}</Typography>
      </Box>
    </Box>
  );
}
