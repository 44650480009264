import { Typography } from "@mui/material";
import History from "../../Admin/Member/History/History"; // Corrected import

export default function HistoryComponent() {
  return (
    <>
      <Typography align="center" variant="h4">Historique</Typography>
      <History />
    </>
  );
}
