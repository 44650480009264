import { useState, useContext } from "react";
import {
  Tabs,
  Tab,
  Box,
  Fab,
  Tooltip,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  Grid,
  Divider,
} from "@mui/material";
import UserContext from "../../../Data/UserContext";
import Day from "./Day";

export default function Meals() {
  const { member } = useContext(UserContext);
  const joursDeLaSemaine = [
    { key: 0, title: "Lundi" },
    { key: 1, title: "Mardi" },
    { key: 2, title: "Mercredi" },
    { key: 3, title: "Jeudi" },
    { key: 4, title: "Vendredi" },
    { key: 5, title: "Samedi" },
    { key: 6, title: "Dimanche" },
  ];

  const currentDayOfWeek = new Date().getDay();
  const adjustedDayOfWeek = currentDayOfWeek === 0 ? 6 : currentDayOfWeek - 1;
  const [selectedTab, setSelectedTab] = useState(adjustedDayOfWeek);
  const [isPopupOpen, setPopupOpen] = useState(false); // New state for popup visibility

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <Box display="flex" justifyContent="center" width="100%">
        <Tabs value={selectedTab} onChange={handleChange} variant="scrollable">
          {joursDeLaSemaine.map((jour) => (
            <Tab
              key={"Tab-" + jour.key.toString()}
              value={jour.key}
              label={jour.title}
            />
          ))}
        </Tabs>
      </Box>
      {member.Program.Days &&
        member.Program.Days.map((day, index) => {
          if (index === selectedTab) {
            // Display information for the selected day
            return <Day key={index} day={day} />;
          }
          return null; // Don't render anything if this isn't the selected day
        })}
      {member.Program.FoodSupplements.length > 0 && (
        <>
          <Tooltip title="Afficher Compléments">
            <Fab
              color="success"
              aria-label="Open Popup"
              sx={{
                position: "fixed",
                bottom: "70px",
                right: "20px",
                backgroundColor: "rgba(0, 128, 0, 0.7)", // Adjust the last value (0.5) for transparency level
              }}
              onClick={() => setPopupOpen(true)}
            >
              <Typography variant="h5">💊</Typography>
            </Fab>
          </Tooltip>

          <Dialog open={isPopupOpen} onClose={() => setPopupOpen(false)}>
            <DialogTitle>Compléments:</DialogTitle>
            <DialogContent>
              {member.Program.FoodSupplements.map((item) => (
                <Box key={item.Soid}>
                  <Grid container>
                    <Grid md xs item>
                      <Typography
                        sx={{ fontWeight: "bold", textDecoration: "underline" }}
                      >
                        {item.Name}:
                      </Typography>
                    </Grid>
                    <Grid md xs item>
                      <Typography align="right">{item.Quantity}</Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                </Box>
              ))}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setPopupOpen(false)} color="primary">
                Fermer
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}
