import { useContext, useEffect, useState } from "react";
import Select from "react-select";
import AssetContext from "../../../../Data/AssetsContext";
import { Food } from "../../../../Data/Assets";

type AlimentGroup = {
  [key: string]: {
    value: string;
    label: string;
  }[];
};

export default function IngredientDropDown({
  ingredientSoid,
  changeAliment,
}: {
  ingredientSoid: string;
  changeAliment: any;
}) {
  const { ingredients } = useContext(AssetContext);

  const [options, setOptions] = useState<
    {
      label: string;
      options: { value: string; label: string }[];
    }[]
  >([]);

  const [selectedOption, setSelectedOption] = useState<{
    value: string;
    label: string;
  } | null>(
    ingredients && ingredientSoid
      ? {
          value: ingredientSoid,
          label: ingredients.find((e) => e.Soid === ingredientSoid)?.Name || "",
        }
      : null
  );

  useEffect(() => {
    const sortedData: AlimentGroup = {};
    ingredients.forEach((item: Food) => {
      if (item.Type) {
        const targets = item.Type.split(",");
        targets.forEach((target: string) => {
          const trimmedTarget = target.trim();
          if (!sortedData[trimmedTarget]) {
            sortedData[trimmedTarget] = [];
          }
          const option = {
            value: item.Soid,
            label: item.Name,
          };
          sortedData[trimmedTarget].push(option);

          // Check if the item name matches the default exercise name
          if (item.Soid === ingredientSoid) {
            setSelectedOption(option);
          }
        });
      }
    });
    const newOptions = Object.entries(sortedData).map(([label, options]) => ({
      label,
      options,
    }));

    setOptions(newOptions);
  }, [ingredients, ingredientSoid]);

  return (
    <div style={{ minWidth: "200px" }}>
      <Select
        value={selectedOption}
        options={options}
        onChange={(e: any) => {
          changeAliment(e.value);
          setSelectedOption(e);
        }}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      />
    </div>
  );
}
