import {
  TableCell,
  TableHead,
  TableRow,
  Table,
  TableContainer,
  Paper,
  TableBody,
} from "@mui/material";
import { ISession } from "../../../../Data/Member";
import { useContext } from "react";
import AssetContext from "../../../../Data/AssetsContext";

export default function SessionRO({
  session,
  memberSoid,
  setVideoUrl,
}: {
  session: ISession;
  memberSoid: string;
  setVideoUrl: any;
}) {
  const {exercices} = useContext(AssetContext);
  // const {loadEndPoint} = useContext(AxiosContext);
  // const openVideo = (routineSoid:string) => {
  //   const baseEndPoint = loadEndPoint();
  //   setVideoUrl(
  //     `${baseEndPoint}/api/Scheduler/Member/${memberSoid}/Session/${session.Soid}/Routine/${routineSoid}/GetVideo`
  //   );
  // };

  return (
    <TableContainer component={Paper} sx={{ margin: "10px" }}>
      <Table size="small">
        <TableHead sx={{ backgroundColor: "whitesmoke" }}>
          <TableRow>
            <TableCell>Exo</TableCell>
            <TableCell>Variante</TableCell>
            <TableCell>Reps</TableCell>
            <TableCell>Series</TableCell>
            <TableCell>Lest</TableCell>
            <TableCell>Recup</TableCell>
            <TableCell>Video?</TableCell>
            <TableCell>Details</TableCell>
            <TableCell>Note</TableCell>
            <TableCell>Observations</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {session.Routines &&
            session.Routines.map((routine) => (
              <TableRow key={routine.Soid}>
                <TableCell>{exercices.find(e => e.Soid === routine.ExerciceSoid)?.Name ?? ""}</TableCell>
                <TableCell>{routine.Variant}</TableCell>
                <TableCell>{routine.Repetitions}</TableCell>
                <TableCell>{routine.Series}</TableCell>
                <TableCell>{routine.Lest}</TableCell>
                <TableCell>{routine.Recuperation}</TableCell>
                <TableCell>{routine.VideoNeeded ? "Oui" : "Non"}</TableCell>
                <TableCell>{routine.Details}</TableCell>
                <TableCell>{routine.Note}</TableCell>
                <TableCell>{routine.MemberObservations}</TableCell>
                {/* <TableCell padding="none">
                  {routine.FileName && (
                    <Button onClick={() => openVideo(routine.Soid)}>
                      <LocalMovies />
                    </Button>
                  )}
                </TableCell> */}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
