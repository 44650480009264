import React from "react";
import { AxiosInstance } from "axios";

interface DefaultAxiosState {
  axiosHelper: AxiosInstance;
  loadEndPoint: any;
}

const defaultUserState: DefaultAxiosState = {
  axiosHelper: {} as AxiosInstance,
  loadEndPoint: "",
};

const AxiosContext = React.createContext<DefaultAxiosState>(defaultUserState);
export default AxiosContext;