import {
  TableCell,
  TextField,
  Checkbox,
  Button,
  debounce,
  IconButton,
} from "@mui/material";
import { useContext, useRef, useState } from "react";
import { IProgram, IRoutine } from "../../../../Data/Member";
import ClearIcon from "@mui/icons-material/Clear";
import ExoDropDown from "./ExoDropDown";
import AxiosContext from "../../../../Data/AxiosContext";
import UserContext from "../../../../Data/UserContext";
import Swal, { SweetAlertOptions } from "sweetalert2";
import { DragHandle } from "@mui/icons-material";

export default function Routine({
  routine,
  sessionSoid,
  routines,
  setRoutines,
  setVideoUrl,
  dragHandleProps,
}: {
  routine: IRoutine;
  sessionSoid: string;
  routines: IRoutine[];
  setRoutines: any;
  setVideoUrl: any;
  dragHandleProps: any;
}) {
  const { axiosHelper } = useContext(AxiosContext);
  const { member, setMember } = useContext(UserContext);
  //Fields
  const [repetitions, setRepetitions] = useState(routine.Repetitions);
  const [series, setSeries] = useState(routine.Series);
  const [lest, setLest] = useState(routine.Lest);
  const [recuperationMinutes, setRecuperationMinutes] = useState(
    Math.floor(routine.Recuperation / 60)
  );
  const [recuperationSeconds, setRecuperationSeconds] = useState(
    routine.Recuperation % 60
  );
  const [videoNeeded, setVideoNeeded] = useState(routine.VideoNeeded);
  const [note, setNote] = useState(routine.Note);
  const [details, setDetails] = useState(routine.Details);
  const [weightSplitted, setWeightSplitted] = useState(routine.WeightSplitted);
  const [variant, setVariant] = useState(routine.Variant);
  const [memberObservations, setMemberObservations] = useState(
    routine.MemberObservations
  );

  const baseRoutine = useRef(JSON.parse(JSON.stringify(routine)) as IRoutine);

  const debouncedUpdates = useRef<any>({}).current;

  const changeExercice = (value: string) => {
    const data: any = {};
    data.Data = value.toString();
    data.FieldName = "ExerciceSoid";
    axiosHelper
      .patch(
        `api/Scheduler/Member/${member.Soid}/Session/${sessionSoid}/Routine/${routine.Soid}/UpdateField`,
        data
      )
      .then((result) => {
        const temp = { ...member };
        temp.Program = result.data as IProgram;
        if (setMember) setMember(temp);
      });
  };

  const updateField = (val: any, fieldName: string) => {
    const data: any = {};
    data.Data = val.toString();
    data.FieldName = fieldName;
    axiosHelper
      .patch(
        `api/Scheduler/Member/${member.Soid}/Session/${sessionSoid}/Routine/${routine.Soid}/UpdateField`,
        data
      )
      .then((result) => {
        const temp = { ...member };
        temp.Program = result.data as IProgram;
        if (setMember) setMember(temp);
      });
  };

  const handleChange = (event: any) => {
    const checkBoxes = ["VideoNeeded", "WeightSplitted"];
    const name = event.target.name;
    const value = checkBoxes.includes(name)
      ? event.target.checked
      : event.target.value;
    switch (name) {
      case "Repetitions":
        setRepetitions(value);
        break;
      case "Series":
        setSeries(value);
        break;
      case "Lest":
        setLest(value);
        break;
      case "Variant":
        setVariant(value);
        break;
      case "VideoNeeded":
        setVideoNeeded(!videoNeeded);
        break;
      case "WeightSplitted":
        setWeightSplitted(!weightSplitted);
        break;
      case "Details":
        setDetails(value);
        break;
      case "Note":
        setNote(value);
        break;
      case "MemberObservations":
        setMemberObservations(value);
        break;
      default:
        break;
    }
    if (name === "RecuperationMinutes" || name === "RecuperationSeconds") {
      getDebouncedUpdate("Recuperation")(
        recuperationMinutes * 60 + recuperationSeconds
      );
    } else {
      getDebouncedUpdate(name)(value);
    }
  };

  const handleRecuperationChange = (event: any) => {
    const name = event.target.name;
    const value = parseInt(event.target.value, 10);

    if (name === "RecuperationMinutes") {
      setRecuperationMinutes(value);
      updateRecuperation(value * 60 + recuperationSeconds);
    } else if (name === "RecuperationSeconds") {
      setRecuperationSeconds(value);
      updateRecuperation(recuperationMinutes * 60 + value);
    }
  };

  const updateRecuperation = (totalSeconds: number) => {
    getDebouncedUpdate("Recuperation")(totalSeconds);
  };

  const getDebouncedUpdate = (fieldName: string) => {
    if (!debouncedUpdates[fieldName]) {
      debouncedUpdates[fieldName] = debounce((val: any) => {
        updateField(val, fieldName);
      }, 500);
    }
    return debouncedUpdates[fieldName];
  };

  const deleteRoutineRow = () => {
    let swalOptions: SweetAlertOptions = {
      title: `T'es sur de vouloir supprimer cet exercice ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      scrollbarPadding: false,
    };
    Swal.fire(swalOptions).then((result) => {
      if (result.isConfirmed) {
        axiosHelper
          .delete(
            `api/Scheduler/Member/${member.Soid}/Session/${sessionSoid}/Routine/${routine.Soid}`
          )
          .then(() => {
            let tempRoutines = routines.filter((r) => r.Soid !== routine.Soid);
            setRoutines(tempRoutines);
          });
      }
    });
  };

  //const openVideo = () => {
  //  const baseEndPoint = loadEndPoint();
  //  setVideoUrl(
  //    `${baseEndPoint}/api/Scheduler/Member/${member.Soid}/Session/${sessionSoid}/Routine/${routine.Soid}/GetVideo`
  //  );
  //};
  return (
    <>
      <TableCell padding="none" sx={{ textAlign: "center" }}>
        <div {...dragHandleProps}>
          <IconButton sx={{ cursor: "grab" }}>
            <DragHandle />
          </IconButton>
        </div>
      </TableCell>
      <TableCell padding="none">
        <ExoDropDown
          exerciceSoid={routine.ExerciceSoid}
          changeExercice={changeExercice}
        />
      </TableCell>
      <TableCell padding="none" sx={{ paddingLeft: "6px", maxWidth: "150px" }}>
        <TextField
          size="small"
          name="Variant"
          value={variant ?? ""}
          onChange={handleChange}
          type="text"
          multiline={true}
          minRows={1}
          maxRows={10}
          inputProps={{
            style: {
              color: baseRoutine.current.Variant === variant ? "black" : "#2ae22a",
            },
          }}
        />
      </TableCell>
      <TableCell padding="none">
        <Checkbox
          name="WeightSplitted"
          checked={weightSplitted}
          onChange={handleChange}
        />
      </TableCell>
      <TableCell padding="none" sx={{ paddingLeft: "6px" }}>
        <TextField
          size="small"
          name="Repetitions"
          value={repetitions}
          onChange={handleChange}
          type="number"
          inputProps={{
            min: "0",
            style: {
              fontSize: 20,
              color:
                baseRoutine.current.Repetitions === repetitions
                  ? "black"
                  : "#2ae22a",
            },
          }}
          style={{ maxWidth: "90px" }}
        />
      </TableCell>
      <TableCell padding="none">
        <TextField
          size="small"
          name="Series"
          value={series}
          onChange={handleChange}
          type="number"
          inputProps={{
            min: "0",
            style: {
              fontSize: 20,
              color: baseRoutine.current.Series === series ? "black" : "#2ae22a",
            },
          }}
          style={{ maxWidth: "90px" }}
        />
      </TableCell>
      <TableCell padding="none">
        <TextField
          size="small"
          name="Lest"
          value={lest}
          onChange={handleChange}
          type="number"
          style={{ width: "120px" }}
          inputProps={{
            min: "0",
            step: "0.5",
            style: {
              fontSize: 20,
              color: baseRoutine.current.Lest === lest ? "black" : "#2ae22a",
            },
          }}
        />
      </TableCell>
      <TableCell padding="none" sx={{ textAlign: "center" }}>
        <TextField
          size="small"
          name="RecuperationMinutes"
          value={recuperationMinutes}
          onChange={handleRecuperationChange}
          type="number"
          inputProps={{
            min: "0",
            style: {
              fontSize: 20,
              color:
                Math.floor(baseRoutine.current.Recuperation / 60) === recuperationMinutes
                  ? "black"
                  : "#2ae22a",
            },
          }}
          style={{ maxWidth: "70px" }}
        />
        <TextField
          size="small"
          name="RecuperationSeconds"
          value={recuperationSeconds}
          onChange={handleRecuperationChange}
          type="number"
          inputProps={{
            min: "0",
            max: "59",
            style: {
              fontSize: 20,
              color:
                baseRoutine.current.Recuperation % 60 === recuperationSeconds
                  ? "black"
                  : "#2ae22a",
            },
          }}
          style={{ maxWidth: "70px" }}
        />
      </TableCell>
      <TableCell padding="none">
        <Checkbox
          name="VideoNeeded"
          checked={videoNeeded}
          onChange={handleChange}
        />
      </TableCell>
      <TableCell padding="none" style={{ maxWidth: "150px" }}>
        <TextField
          size="small"
          name="Details"
          value={details ?? ""}
          onChange={handleChange}
          multiline={true}
          minRows={1}
          maxRows={10}
          inputProps={{
            min: "0",
            max: "59",
            style: {
              color: baseRoutine.current.Details === details ? "black" : "#2ae22a",
            },
          }}
        />
      </TableCell>
      <TableCell
        padding="none"
        sx={{ borderLeft: "1px dotted grey", marginLeft: "5px" }}
      >
        <TextField
          size="small"
          name="Note"
          value={note}
          onChange={handleChange}
          type="number"
          inputProps={{
            min: "0",
            max: "10",
            style: { fontSize: 20 },
          }}
        />
      </TableCell>
      <TableCell padding="none" style={{ maxWidth: "150px" }}>
        <TextField
          size="small"
          name="MemberObservations"
          value={memberObservations}
          onChange={handleChange}
          multiline={true}
        />
      </TableCell>
      {/* <TableCell padding="none">
        { {fileName && (
          <Button onClick={openVideo}>
            <LocalMoviesIcon />
          </Button>
        )} }
      </TableCell> */}
      <TableCell padding="none">
        <Button onClick={deleteRoutineRow}>
          <ClearIcon />
        </Button>
      </TableCell>
    </>
  );
}
