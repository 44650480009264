import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import AxiosContext from "../../Data/AxiosContext";
import { IUser } from "../../Data/Member";
import Select from "react-select";

export function PopupCopySession({
  copyMemberSoid,
  setCopyMemberSoid,
}: {
  copyMemberSoid: string | null;
  setCopyMemberSoid: any;
}) {
  const { axiosHelper } = useContext(AxiosContext);
  const [memberList, setMemberList] = useState<IUser[]>([]);
  const [selectedMemberSoid, setSelectedMemberSoid] = useState("");
  const [value, setValue] = React.useState("Entrainement");

  const [membersOptions, setMembersOptions] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  useEffect(() => {
    axiosHelper("/api/Scheduler/Members/MemberList").then((result) => {
      setMemberList(result.data);
    });
  }, [axiosHelper]);

  useEffect(() => {
    const tempOptions: any = [];
    if (memberList) {
      memberList.forEach((member) => {
        if (member.Soid === copyMemberSoid) return;
        let option: any = {};
        option.value = member.Soid;
        option.label = `${member.FirstName} ${member.LastName}`;
        tempOptions.push(option);
      });
    }
    setMembersOptions(tempOptions);
  }, [memberList, copyMemberSoid]);

  const handleClose = () => {
    setSelectedMemberSoid("");
    setCopyMemberSoid(null);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const copy = () => {
    axiosHelper.post(
      `api/Scheduler/Member/${copyMemberSoid}/CopyFrom/${selectedMemberSoid}/Type/${value}`
    ).then(() => {
      setSelectedMemberSoid("");
      setCopyMemberSoid(null);
    });
  };

  return (
    <Dialog
      open={copyMemberSoid != null}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Copier la session depuis un autre membre"}
      </DialogTitle>
      <DialogContent sx={{ minHeight: "250px" }}>
        <Grid container>
          <Grid item xs={5}>
            <Select
              options={membersOptions}
              onChange={(e: any) => {
                setSelectedMemberSoid(e.value);
              }}
            />
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={5}>
            {selectedMemberSoid && (
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={value}
                onChange={handleChange}
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="Entrainement"
                  control={<Radio />}
                  label="Entrainement"
                />
                <FormControlLabel
                  value="Diete"
                  control={<Radio />}
                  label="Diète"
                />
                <FormControlLabel
                  value="Both"
                  control={<Radio />}
                  label="Les Deux"
                />
              </RadioGroup>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Annuler</Button>
        <Button onClick={copy} autoFocus>
          Copier !
        </Button>
      </DialogActions>
    </Dialog>
  );
}
