import {
  Button,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { IMeal, IMealItem } from "../../../../Data/Member";
import { useContext, useEffect, useRef, useState } from "react";
import AssetContext from "../../../../Data/AssetsContext";
import { Delete } from "@mui/icons-material";
import IngredientDropDown from "./IngredientDropDown";
import UserContext from "../../../../Data/UserContext";
import AxiosContext from "../../../../Data/AxiosContext";
import Swal, { SweetAlertOptions } from "sweetalert2";
import { debounce } from "@mui/material";

export default function IngredientRow({
  item,
  daySoid,
  mealSoid,
}: {
  item: IMealItem;
  daySoid: string;
  mealSoid: string;
}) {
  const { ingredients } = useContext(AssetContext);
  const { member, setMember } = useContext(UserContext);
  const { axiosHelper } = useContext(AxiosContext);
  const [currentFoodSoid, setCurrentFoodSoid] = useState(item.FoodSoid);
  const [quantity, setQuantity] = useState<number>(item.Quantity);
  const [glucides, setGlucides] = useState<string>("");
  const [lipides, setLipides] = useState<string>("");
  const [proteines, setProteines] = useState<string>("");
  const [calories, setCalories] = useState<string>("");
  const debouncedUpdates = useRef<any>({}).current;

  useEffect(() => {
    const aliment = ingredients.find((a) => a.Soid === currentFoodSoid);
    if (aliment) {
      setGlucides((aliment.Glucides * quantity).toFixed(2));
      setLipides((aliment.Lipides * quantity).toFixed(2));
      setProteines((aliment.Proteines * quantity).toFixed(2));
      setCalories(
        (
          parseFloat(glucides) * 4 +
          parseFloat(proteines) * 4 +
          parseFloat(lipides) * 9
        ).toFixed(2)
      );
    }
  }, [quantity, ingredients, glucides, currentFoodSoid, lipides, proteines]);

  const updateMealItem = (val: any, fieldName: string) => {
    const data: any = {};
    data.Data = val.toString();
    data.FieldName = fieldName;
    axiosHelper
      .patch(
        `api/Scheduler/Member/${member.Soid}/Day/${daySoid}/Meal/${mealSoid}/MealItem/${item.Soid}/UpdateField`,
        data
      )
      .then((result) => {
        const newMeal = result.data as IMeal;
        const temp = { ...member };
        const dayIndex = temp.Program.Days.findIndex((d) => d.Soid === daySoid);
        if (dayIndex !== -1) {
          const mealIndex = temp.Program.Days[dayIndex].Meals.findIndex(
            (m) => m.Soid === mealSoid
          );
          if (mealIndex !== -1) {
            temp.Program.Days[dayIndex].Meals.splice(mealIndex, 1, newMeal);
            setMember(temp);
          }
        }
      });
  };

  const getDebouncedUpdate = (fieldName: string) => {
    if (!debouncedUpdates[fieldName]) {
      debouncedUpdates[fieldName] = debounce((val: any) => {
        updateMealItem(val, fieldName);
      }, 800);
    }
    return debouncedUpdates[fieldName];
  };

  const handleQuantity = (ev: any) => {
    setQuantity(parseInt(ev.target.value));
    getDebouncedUpdate("Quantity")(ev.target.value.toString());
  };

  const changeAliment = (alimentSoid: string) => {
    setCurrentFoodSoid(alimentSoid);
    getDebouncedUpdate("FoodSoid")(alimentSoid);
  };

  const deleteMealItem = () => {
    let swalOptions: SweetAlertOptions = {
      title: `T'es sur de vouloir supprimer cet aliment ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      scrollbarPadding: false,
    };
    Swal.fire(swalOptions).then((result) => {
      if (result.isConfirmed) {
        axiosHelper
          .delete(
            `api/Scheduler/Member/${member.Soid}/Day/${daySoid}/Meal/${mealSoid}/MealItem/${item.Soid}`
          )
          .then(() => {
            const temp = { ...member };
            const day = temp.Program.Days.find((d) => d.Soid === daySoid);
            const meal = day?.Meals.find((m) => m.Soid === mealSoid);
            const mealItemIndex = meal?.MealItems.findIndex(
              (i) => i.Soid === item.Soid
            );
            if (
              meal &&
              mealItemIndex !== null &&
              typeof mealItemIndex !== "undefined" &&
              mealItemIndex !== -1
            ) {
              meal?.MealItems.splice(mealItemIndex, 1);
            }
            setMember(temp);
          });
      }
    });
  };

  return (
    <TableRow>
      <TableCell>
        <IngredientDropDown
          changeAliment={changeAliment}
          ingredientSoid={item.FoodSoid}
        />
      </TableCell>
      <TableCell>
        <TextField
          sx={{ backgroundColor: "white" }}
          size="small"
          type="number"
          value={quantity}
          onChange={handleQuantity}
          inputProps={{
            step: "5",
            min: "0",
          }}
        />
      </TableCell>
      <TableCell>
        <Typography>{glucides}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{lipides}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{proteines}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{calories}</Typography>
      </TableCell>
      <TableCell>
        <Button onClick={deleteMealItem}>
          <Delete />
        </Button>
      </TableCell>
    </TableRow>
  );
}
