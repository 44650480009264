import { TableCell, TextField, Button, IconButton } from "@mui/material";
import { Delete, DragHandle } from "@mui/icons-material";
import { debounce } from "@mui/material";
import { useContext, useRef, useState } from "react";
import UserContext from "../../../../Data/UserContext";
import AxiosContext from "../../../../Data/AxiosContext";
import { IFoodSupplement } from "../../../../Data/Member";

export default function FoodComplementRow({
  item,
  dragHandleProps,
}: {
  item: IFoodSupplement;
  dragHandleProps: any
}) {
  const { member, setMember } = useContext(UserContext);
  const { axiosHelper } = useContext(AxiosContext);
  const [aliment, setAliment] = useState(item.Name);
  const [quantity, setQuantity] = useState(item.Quantity);
  const debouncedUpdates = useRef<any>({}).current;

  const deleteComplement = () => {
    axiosHelper
      .delete(
        `/api/Scheduler/Member/${member.Soid}/FoodSupplement/${item.Soid}`
      )
      .then((_) => {
        const temp = { ...member };
        temp.Program.FoodSupplements = temp.Program.FoodSupplements.filter(
          (supplement) => supplement.Soid !== item.Soid
        );
        setMember(temp);
      });
  };

  const updateField = (val: any, fieldName: string) => {
    const data: any = {};
    data.Data = val.toString();
    data.FieldName = fieldName;
    axiosHelper
      .patch(
        `api/Scheduler/Member/${member.Soid}/FoodSupplement/${item.Soid}/UpdateField`,
        data
      )
      .then((_) => {
        //const temp = { ...member };
        //temp.Program = result.data as IProgram;
        //if (setMember) setMember(temp);
      });
  };

  const getDebouncedUpdate = (fieldName: string) => {
    if (!debouncedUpdates[fieldName]) {
      debouncedUpdates[fieldName] = debounce((val: any) => {
        updateField(val, fieldName);
      }, 500);
    }
    return debouncedUpdates[fieldName];
  };

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    switch (name) {
      case "Name":
        setAliment(value);
        break;
      case "Quantity":
        setQuantity(value);
        break;
      default:
        break;
    }
    getDebouncedUpdate(name)(value);
  };

  return (
    <>
      <TableCell padding="none" sx={{ textAlign: "center" }}>
        <div {...dragHandleProps}>
          <IconButton sx={{ cursor: "grab" }}>
            <DragHandle />
          </IconButton>
        </div>
      </TableCell>
      <TableCell align="center">
        <TextField value={aliment} name="Name" onChange={handleChange} />
      </TableCell>
      <TableCell align="center">
        <TextField name="Quantity" value={quantity} onChange={handleChange} />
      </TableCell>
      <TableCell align="center">
        <Button onClick={deleteComplement}>
          <Delete />
        </Button>
      </TableCell>
    </>
  );
}
