import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import AxiosContext from "../../Data/AxiosContext";
import { IUser } from "../../Data/Member";

export default function AddNewMember({
  isOpen,
  setIsOpen,
  members,
  setMembers,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  members: IUser[];
  setMembers: (memberList: IUser[]) => void;
}) {
  const { axiosHelper } = useContext(AxiosContext);
  const [firstName, setFirstName] = useState("");
  const [userName, setUserName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [errorSaving, setErrorSaving] = useState(null);

  const saveMember = () => {
    const member: any = {};
    member.FirstName = firstName;
    member.UserName = userName;
    member.LastName = lastName;
    member.EmailAddress = emailAddress;
    member.Password = password;
    axiosHelper
      .post("/api/Scheduler/Members/AddMember", member)
      .then((result) => {
        const temp = [...members];
        temp.push(result.data as IUser);
        setMembers(temp);
        setIsOpen(false);
      })
      .catch((error) => {
        setErrorSaving(error.response.data);
      });
  };

  const isUserNameEmpty = userName.trim() === "";
  const isPasswordEmpty = password.trim() === "";

  const alphanumericDashRegex = /^[a-zA-Z0-9-]+$/;
  const handleUserNameChange = (e: any) => {
    const inputValue = e.target.value;
    if (inputValue === "" || alphanumericDashRegex.test(inputValue)) {
      setUserName(inputValue);
    }
  };

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <DialogTitle>Ajouter Nouveau Membre</DialogTitle>
      <DialogContent>
        <Box>
          <Grid container>
            <Grid item xs={6}>
              <Box p={1}>
                <Typography>Prénom</Typography>
                <TextField
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box p={1}>
                <Typography>Nom</Typography>
                <TextField
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <Box p={1}>
                <Typography>Identifiant</Typography>
                <TextField
                  value={userName}
                  onChange={handleUserNameChange}
                  autoComplete="false"
                  error={isUserNameEmpty}
                  helperText={isUserNameEmpty ? "Requis" : ""}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box p={1}>
                <Typography>Mot de passe</Typography>
                <TextField
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="false"
                  error={isPasswordEmpty}
                  helperText={isPasswordEmpty ? "Requis" : ""}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <Box p={1}>
                <Typography>Adresse Email</Typography>
                <TextField
                  value={emailAddress}
                  onChange={(e) => setEmailAddress(e.target.value)}
                />
              </Box>
            </Grid>
          </Grid>
          {errorSaving && (
            <Box>
              <Typography textAlign={"center"} color={"red"}>
                {errorSaving}
              </Typography>
            </Box>
          )}
          <Box textAlign={"center"}>
            <Button
              color={errorSaving ? "error" : "primary"}
              variant="outlined"
              onClick={saveMember}
              disabled={isUserNameEmpty || isPasswordEmpty}
            >
              Sauver
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
