import { Download, Upload } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { useContext, useRef, useState } from "react";
import AxiosContext from "../../../Data/AxiosContext";
import Swal, { SweetAlertOptions } from "sweetalert2";

export default function Options() {
  const { axiosHelper } = useContext(AxiosContext);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isWPDownloading, setIsWPDownloading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const backupAll = () => {
    setIsDownloading(true);
    axiosHelper("/api/Admin/Backup")
      .then((result) => {
        const jsonData = JSON.stringify(result.data);
        const blob = new Blob([jsonData], { type: "application/json" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        const currentDate = new Date().toISOString().split("T")[0];
        link.href = url;
        link.download = `backup_${currentDate}.json`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files !== null) {
      const file = event.target.files[0];
      restore(file);
    }
  };

  const backupWP = () => {
    try {
      const url = "/api/Admin/BackupWP"; // Replace with your actual endpoint
      setIsWPDownloading(true);
      axiosHelper({
        url,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        // Create a URL for the blob
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", "BackupWP.zip");
        document.body.appendChild(fileLink);
        fileLink.click();
        fileLink.remove();
        window.URL.revokeObjectURL(fileURL);
        setIsWPDownloading(false);
      });
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  const restore = (file: File) => {
    let swalOptions: SweetAlertOptions = {
      title: `ATTENTION: LA RESTAURATION VA EFFACER LA BASE DE DONNEES ET LA RECREER !`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Continuer",
      cancelButtonText: "Annuler",
      scrollbarPadding: false,
    };
    Swal.fire(swalOptions).then((result) => {
      let swalOptions2: SweetAlertOptions = {
        title: `ATTENTION: Cette opération n'est pas anodine et est à utiliser qu'en cas de problème !`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Continuer",
        cancelButtonText: "Annuler",
        scrollbarPadding: false,
      };
      Swal.fire(swalOptions2).then((response) => {
        if (response.isConfirmed) {
          var data = new FormData();
          data.append("file", file);
          axiosHelper.post(`/api/Admin/Restore`, data).then((result) => {
            Swal.fire({
              title: "Restauration réussie",
              icon: "success",
            });
          });
        }
      });
    });
  };

  return (
    <Box>
      <Grid container>
        <Grid item sm textAlign={"center"}>
          <Box>
            <Typography variant="h4">Backup Appli</Typography>
            <Button onClick={backupAll}>
              {!isDownloading ? <Download /> : <CircularProgress />}
            </Button>
          </Box>
          <Box>
            <Typography variant="h4">Backup Site (Attention, c'est long)</Typography>
            <Button onClick={backupWP}>
              {!isWPDownloading ? <Download /> : <CircularProgress />}
            </Button>
          </Box>
        </Grid>
        <Grid item sm textAlign={"center"}>
          <Typography variant="h4" align="center">
            Restore Appli
          </Typography>
          <Button onClick={() => fileInputRef?.current?.click()}>
            <input
              type="file"
              style={{ display: "none" }}
              onChange={handleFileUpload}
              ref={fileInputRef}
            />
            <Upload />
          </Button>
        </Grid>
      </Grid>
      <Divider />
    </Box>
  );
}
