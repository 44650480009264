import * as React from "react";
import SignIn from "../Common/SignIn";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import DescriptionIcon from "@mui/icons-material/Description";
import MemberList from "./MemberList";
import { AppBar, Box, IconButton, Paper, Toolbar } from "@mui/material";
import Assets from "./Assets";
import AxiosContext from "../../Data/AxiosContext";
import { Logout, Settings } from "@mui/icons-material";
import Options from "./Options/Options";

export default function Administration() {
  const { axiosHelper } = React.useContext(AxiosContext);
  const [isLoggedIn, setIsLoggedIn] = React.useState<string | null>(null);
  const [value, setValue] = React.useState(
    window.location.hash.substr(1) || "Programmes"
  );

  React.useEffect(() => {
    if (typeof localStorage.User !== "undefined" && localStorage.User !== "")
      setIsLoggedIn(localStorage.User);
  }, []);

  const logout = () => {
    localStorage.removeItem("User");
    setIsLoggedIn(null);
  };

  
  const handleNavigationChange = (
    event: React.ChangeEvent<{}>,
    newValue: string
  ) => {
    window.location.hash = newValue;
    setValue(newValue);
  };

  if (isLoggedIn) {
    return (
      <>
        <AppBar position="static" color={window.location.hostname === "localhost" ? "secondary" : "primary"}>
          <Toolbar variant="dense">
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { md: "flex" } }}>
              <IconButton size="large" edge="end" onClick={logout}>
                <Logout />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        <Box mt={2} sx={{paddingBottom: "60px"}}>
          {value === "Programmes" && <MemberList />}
          {value === "Assets" && <Assets />}
          {value === "Options" && <Options />}
        </Box>
        <Paper
          sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
          elevation={3}
        >
          <BottomNavigation
            showLabels
            value={value}
            onChange={handleNavigationChange}
          >
            <BottomNavigationAction
              value="Programmes"
              label="Programmes"
              icon={<FitnessCenterIcon />}
            />
            <BottomNavigationAction
              value="Assets"
              label="Ressources"
              icon={<DescriptionIcon />}
            />
            <BottomNavigationAction
              value="Options"
              label="Options"
              icon={<Settings />}
            />
          </BottomNavigation>
        </Paper>
      </>
    );
  } else {
    return (
      <SignIn
        axiosHelper={axiosHelper}
        setIsLoggedIn={setIsLoggedIn}
        realm="Admin"
      />
    );
  }
}
