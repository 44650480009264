import { Box, SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import { IDay, IUser } from "../../../../Data/Member";
import { Add, ImportExport, Restaurant } from "@mui/icons-material";
import Meal from "./Meal";
import { useContext, useState } from "react";
import AxiosContext from "../../../../Data/AxiosContext";
import UserContext from "../../../../Data/UserContext";
import AddNewIngredient from "../../Assets/Food/AddNewIngredient";
import AssetContext from "../../../../Data/AssetsContext";
import TotalSummary from "./TotalSummary";
import { PopupCopyMeal } from "./PopupCopyMeal";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable as Droppable } from "../../../Common/Droppable";

export default function Day({
  day,
  isVisible,
}: {
  day: IDay;
  isVisible: boolean;
}) {
  const { axiosHelper } = useContext(AxiosContext);
  const { member, setMember } = useContext(UserContext);
  const { ingredients } = useContext(AssetContext);
  const [isAddNewVisible, setIsAddNewVisible] = useState(false);
  const [daySoid, setDaySoid] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let lipides = 0;
  let glucides = 0;
  let proteines = 0;
  day.Meals.forEach((meal) => {
    meal.MealItems.forEach((mealItem) => {
      const ingredient = ingredients.find((i) => i.Soid === mealItem.FoodSoid);
      if (ingredient) {
        lipides += ingredient.Lipides * mealItem.Quantity;
        glucides += ingredient.Glucides * mealItem.Quantity;
        proteines += ingredient.Proteines * mealItem.Quantity;
      }
    });
  });

  const openImportPopup = () => {
    setDaySoid(day.Soid);
  };

  const addMeal = () => {
    axiosHelper
      .post(`api/Scheduler/Member/${member.Soid}/Day/${day.Soid}/AddMeal`)
      .then((result) => {
        const newDay = result.data as IDay;
        const temp = { ...member };
        const updatedDays = temp.Program.Days.map((x) => {
          if (day.Soid === x.Soid) {
            return newDay;
          }
          return x;
        });
        temp.Program.Days = updatedDays;
        setMember(temp);
      });
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;
    const oldMember: IUser = JSON.parse(JSON.stringify(member));
    const temp = { ...member };
    const tempMeals = temp.Program.Days.find((d) => d.Soid === day.Soid)?.Meals;
    if (tempMeals) {
      const [removed] = tempMeals.splice(result.source.index, 1);
      tempMeals.splice(result.destination.index, 0, removed);
      setMember(temp);
      let data: string[] = [];
      tempMeals.forEach((m) => {
        data.push(m.Soid);
      });
      axiosHelper
        .patch(
          `api/Scheduler/Member/${member.Soid}/Day/${day.Soid}/UpdateDayMealOrder`,
          data
        )
        .then()
        .catch(() => setMember(oldMember));
    }
  };

  return (
    <Box mt={2} sx={{ display: isVisible ? "block" : "none" }}>
      <TotalSummary
        lipides={lipides}
        glucides={glucides}
        proteines={proteines}
      />
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="routines-list" direction="vertical">
          {(provided) => (
            <Box ref={provided.innerRef} {...provided.droppableProps}>
              {day.Meals.map((meal, index) => (
                <Draggable
                  key={meal.Soid} // Assuming routine.Soid is unique
                  draggableId={meal.Soid} // Use a prefix to ensure uniqueness
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Meal
                        key={meal.Soid}
                        meal={meal}
                        index={index}
                        daySoid={day.Soid}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
      <TotalSummary
        lipides={lipides}
        glucides={glucides}
        proteines={proteines}
      />
      <Box
        sx={{
          transform: "translateZ(0px)",
          flexGrow: 1,
          position: "fixed",
          bottom: "70px",
          right: "20px",
        }}
      >
        <SpeedDial
          ariaLabel="SpeedDial tooltip example"
          icon={<SpeedDialIcon />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
        >
          <SpeedDialAction
            icon={<Add />}
            tooltipTitle={"Nouvel Ingrédient"}
            onClick={() => {
              setIsAddNewVisible(true);
              handleClose();
            }}
          />
          <SpeedDialAction
            icon={<Restaurant />}
            tooltipTitle={"Ajouter Repas"}
            onClick={() => {
              addMeal();
              handleClose();
            }}
          />
          <SpeedDialAction
            icon={<ImportExport />}
            tooltipTitle={"Importer Repas"}
            onClick={() => {
              openImportPopup();
              handleClose();
            }}
          />
        </SpeedDial>
      </Box>
      <AddNewIngredient open={isAddNewVisible} setIsOpen={setIsAddNewVisible} />
      {daySoid && 
        <PopupCopyMeal daySoid={daySoid} setDaySoid={setDaySoid} />
      }
    </Box>
  );
}
