import React, { Dispatch, SetStateAction } from "react";
import { Food, Exercice } from "./Assets";

interface DefaultAssetState {
  ingredients: Food[];
  exercices: Exercice[];
  setExercices: Dispatch<SetStateAction<Exercice[]>>;
  setIngredients: Dispatch<SetStateAction<Food[]>>;
}

const defaultAssetState: DefaultAssetState = {
  ingredients: [] as Food[],
  exercices: [] as Exercice[],
  setExercices: () => {},
  setIngredients: () => {}, 
};

const AssetContext = React.createContext<DefaultAssetState>(defaultAssetState);
export default AssetContext;
