import { IRoutine } from "../../../Data/Member";
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Grid,
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Divider,
  Slider,
} from "@mui/material";
import UserContext from "../../../Data/UserContext";
import { debounce } from "@mui/material";
import AxiosContext from "../../../Data/AxiosContext";
import AssetContext from "../../../Data/AssetsContext";
import { Link } from "@mui/material";
import Linkify from "react-linkify";
import "./Timer.css";

const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}':${remainingSeconds.toString().padStart(2, "0")}"`;
};

export default function Routine({
  routine,
  sessionSoid,
  setTimer,
  setIsTimerDisplayed,
  expanded,
}: {
  routine: IRoutine;
  sessionSoid: string;
  setTimer: any;
  setIsTimerDisplayed: any;
  expanded: boolean;
}) {
  const { exercices } = useContext(AssetContext);
  const { member, setMember } = useContext(UserContext);
  const { axiosHelper } = useContext(AxiosContext);
  const [fileName, setFileName] = useState(routine.FileName);
  const [note, setNote] = useState<number>(routine.Note);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [observations, setObservations] = useState<string>(
    routine.MemberObservations
  );
  const [currentSeries, setCurrentSeries] = useState(0);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const exercice = exercices.find((e) => e.Soid === routine.ExerciceSoid);
  const debouncedUpdates = useRef<any>({}).current;

const marks = [
  {
    value: 0,
    label: "😴", // Sleepy or not challenging
  },
  {
    value: 1,
    label: "😁", // Easy
  },
  {
    value: 2,
    label: "😉", // Slightly easy
  },
  {
    value: 3,
    label: "😐", // Neutral
  },
  {
    value: 4,
    label: "🤔", // Thoughtful, a bit challenging
  },
  {
    value: 5,
    label: "😕", // Slightly hard
  },
  {
    value: 6,
    label: "😬", // Challenging
  },
  {
    value: 7,
    label: "😧", // Difficult
  },
  {
    value: 8,
    label: "😰", // Very difficult
  },
  {
    value: 9,
    label: "😱", // Extremely difficult
  },
  {
    value: 10,
    label: "🥵", // Overwhelmingly hard
  },
];

  const updateField = (val: any, fieldName: string) => {
    const data: any = {};
    data.Data = val.toString();
    data.FieldName = fieldName;
    axiosHelper
      .patch(
        `api/Scheduler/Member/${member.Soid}/Session/${sessionSoid}/Routine/${routine.Soid}/UpdateField`,
        data
      )
      .then((result) => {
        const temp = { ...member };
        temp.Program = result.data;
        setMember(temp);
      });
  };

  const getDebouncedUpdate = (fieldName: string) => {
    if (!debouncedUpdates[fieldName]) {
      debouncedUpdates[fieldName] = debounce((val: any) => {
        updateField(val, fieldName);
      }, 800);
    }
    return debouncedUpdates[fieldName];
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setIsUploading(true);
      const data = new FormData();
      data.append("File", event.target.files[0]);
      axiosHelper
        .post(
          `api/Scheduler/Member/${member.Soid}/Session/${sessionSoid}/Routine/${routine.Soid}/Video`,
          data
        )
        .then((result: any) => {
          setIsUploading(false);
          setFileName(result.data);
        });
    }
  };

  const handleNoteChange = (event: Event, value: number|number[]) => {
    if (value === null) return;
    if(value === 0) value = 1;
    setNote(value as number);
    if (!isInitialRender) {
      getDebouncedUpdate("Note")(value);
    }
  };

  const handleObservationsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setObservations(event.target.value);
    if (!isInitialRender) {
      getDebouncedUpdate("MemberObservations")(event.target.value);
    }
  };

  useEffect(() => {
    setIsInitialRender(false);
  }, []);

  const renderUnitType = (unitType: string) => {
    let unit = "";
    switch (unitType) {
      case "Distance":
        unit = " mètres";
        break;
    }
    return (
      <Typography fontSize={15} sx={{ display: "inline" }}>
        {unit}
      </Typography>
    );
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
      }}
    >
      {exercice?.Url && (
        <Box>
          <Link href={exercice.Url} target="_blank">
            -- Site --
          </Link>
        </Box>
      )}
      {exercice?.YoutubeUrl && expanded && (
        <Box>
          <iframe
            src={exercice.YoutubeUrl.replace("watch?v=", "embed/").replace(
              "https://youtu.be/",
              "https://www.youtube.com/embed/"
            )}
            width="100%"
            height="100%"
            frameBorder="0"
            allowFullScreen
            title="Youtube Video"
          />
        </Box>
      )}
      <Box sx={{ width: isMobile ? 1 : 0.5 }}>
        <Grid
          container
          spacing={0}
          textAlign={"center"}
          sx={{ border: "2px solid", borderRadius: "25px" }}
        >
          <Grid
            item
            xs={6}
            sx={{ borderBottom: "1px solid", borderRight: "1px solid" }}
          >
            {exercice?.HasTimer ? (
              <div
                onClick={() => {
                  setTimer(routine.Repetitions);
                  setIsTimerDisplayed(true);
                }}
                className="Timer"
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  Temps de travail
                </Typography>
                <Typography fontSize={22}>
                  {formatTime(routine.Repetitions)}
                </Typography>
              </div>
            ) : (
              <>
                <Typography sx={{ fontWeight: "bold" }}>Répétitions</Typography>
                <Typography fontSize={22}>{routine.Repetitions}</Typography>
              </>
            )}
          </Grid>
          <Grid item xs={6} sx={{ borderBottom: "1px solid" }}>
            <Typography sx={{ fontWeight: "bold" }}>
              {exercice?.UnitType ?? ""}
            </Typography>
            <Typography fontSize={22} sx={{ display: "inline" }}>
              {routine.Lest}
              <Typography fontSize={15} sx={{ display: "inline" }}>
                {routine.WeightSplitted ? " (par coté)" : ""}
              </Typography>
              {renderUnitType(exercice?.UnitType ?? "")}
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ borderRight: "1px solid" }}>
            <div
              onClick={() => {
                if (currentSeries < routine.Series)
                  setCurrentSeries(currentSeries + 1);
                else setCurrentSeries(1);
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>Séries</Typography>
              <Typography fontSize={22}>
                {currentSeries > 0 && (
                  <span style={{ fontSize: "20px" }}>{currentSeries}/</span>
                )}
                {routine.Series}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div
              onClick={() => {
                setTimer(routine.Recuperation);
                setIsTimerDisplayed(true);
                if (currentSeries < routine.Series)
                  setCurrentSeries(currentSeries + 1);
              }}
              className="Timer"
            >
              <Typography sx={{ fontWeight: "bold" }}>Récup</Typography>
              <Typography fontSize={22}>
                {formatTime(routine.Recuperation)}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Box>
      {routine.Variant && routine.Variant.trim() !== "" && (
        <Box sx={{ width: isMobile ? 1 : 0.5 }}>
          <Typography
            sx={{ textDecoration: "underline", fontWeight: "bold" }}
            textAlign="center"
          >
            Variante:
          </Typography>
          <Typography color={"green"} fontSize={22} textAlign="center">
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a target="blank" href={decoratedHref} key={key}>
                  --Lien--
                </a>
              )}
            >
              {routine.Variant}
            </Linkify>
          </Typography>
        </Box>
      )}
      {routine.Details && routine.Details.trim() !== "" && (
        <Box sx={{ width: isMobile ? 1 : 0.5 }}>
          <Typography
            sx={{ textDecoration: "underline", fontWeight: "bold" }}
            textAlign="center"
          >
            Details:
          </Typography>
          <Typography color="red" fontSize={22} textAlign="center">
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a target="blank" href={decoratedHref} key={key}>
                  --Lien--
                </a>
              )}
            >
              {routine.Details}
            </Linkify>
          </Typography>
        </Box>
      )}
      {routine.VideoNeeded && (
        <>
          <Divider color="grey" sx={{ height: 1, width: "92px" }} />
          <Box sx={{ width: isMobile ? 1 : 0.5 }}>
            <Typography color="blue" fontSize={26} textAlign="center">
              🎥 Merci de te filmer sur la dernière série de cet exercice !
            </Typography>
          </Box>
          <Divider color="grey" sx={{ height: 1, width: "92px" }} />
        </>
      )}
      <Box sx={{ width: isMobile ? 1 : 0.5 }} textAlign="center">
        <Typography
          gutterBottom
          sx={{ textDecoration: "underline", fontWeight: "bold" }}
          textAlign={"center"}
        >
          Difficulté:
        </Typography>
        <Slider
          value={note}
          onChange={handleNoteChange}
          step={1}
          min={0}
          max={10}
          marks={marks}
          valueLabelDisplay="auto"
          aria-labelledby="difficulte-slider"
          sx={{
            width: isMobile ? "70%" : "100%", 
            "& .MuiSlider-markLabel": {
              fontSize: isMobile ? "1.2rem" : "1.5rem",
            },
          }}
        />
      </Box>
      <Box sx={{ width: isMobile ? 1 : 0.5 }}>
        <Typography
          sx={{ textDecoration: "underline", fontWeight: "bold" }}
          textAlign="center"
        >
          Observations:
        </Typography>
        <TextField
          value={observations ?? ""}
          onChange={handleObservationsChange}
          multiline
          minRows={2}
          fullWidth
        />
      </Box>
      {/* Virer le && false si on veut remettre l'upload */}
      {routine.VideoNeeded && false && (
        <Box>
          <Button
            variant="contained"
            component="label"
            sx={{ backgroundColor: fileName ? "#4caf50" : "" }}
          >
            {!isUploading ? (
              <Box>{fileName ? "Changer Video" : "Envoyer Video"}</Box>
            ) : (
              <CircularProgress color="warning" size={30} />
            )}
            <input
              type="file"
              hidden
              accept="video/*"
              onChange={handleFileUpload}
            />
          </Button>
        </Box>
      )}
    </Box>
  );
}
