import { Box, Tabs, Tab, Fab, Tooltip } from "@mui/material";
import { TabPanel } from "../TabPanel";
import { useContext, useEffect, useState } from "react";
import Session from "./Session";
import AxiosContext from "../../../../Data/AxiosContext";
import DraggableDialog from "./DraggableDialog";
import { Check, DeleteSweep } from "@mui/icons-material";
import Swal, { SweetAlertOptions } from "sweetalert2";
import UserContext from "../../../../Data/UserContext";
import { isMobile } from "react-device-detect";
import QuestionsDrawer from "../QuestionsDrawer";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function EntrainementList() {
  const { axiosHelper } = useContext(AxiosContext);
  const { member, setMember } = useContext(UserContext);
  const [value, setValue] = useState<number>(0);
  const [videoUrl, setVideoUrl] = useState<string>("");
  const [openDialog, setOpenDialog] = useState(false);

  const deleteAllUserInfos = () => {
    let swalOptions: SweetAlertOptions = {
      title: `Cette action va supprimer TOUTES les informations utilisateur (Bilan + Exos individuels). Continuer ?`,
      icon: "warning",
      iconColor: "red",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      scrollbarPadding: false,
    };
    Swal.fire(swalOptions).then((result) => {
      if (result.isConfirmed) {
        axiosHelper
          .delete(`/api/Scheduler/Member/${member.Soid}/Informations`)
          .then((result) => {
            setMember((prev) => {
              if (prev) {
                return { ...prev, Program: result.data };
              } else {
                return prev;
              }
            });
          });
      }
    });
  };

  useEffect(() => {
    if (videoUrl !== "") setOpenDialog(true);
  }, [videoUrl]);

  const addNewSession = () => {
    axiosHelper
      .post(`/api/Scheduler/Member/${member.Soid}/AddEmptySession`)
      .then((result) => {
        let tempMember = { ...member };
        tempMember.Program.Sessions.push(result.data);
        setMember(tempMember);
      });
  };

  const validateBilan = () => {
    let swalOptions: SweetAlertOptions = {
      title: `T'es sur de vouloir valider le bilan ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      scrollbarPadding: false,
    };
    Swal.fire(swalOptions).then((result) => {
      if (result.isConfirmed) {
        axiosHelper
          .post(`api/Scheduler/Member/${member.Soid}/Bilan`)
          .then(() => {
            const temp = { ...member };
            temp.BilanSent = true;
            setMember(temp);
          });
      }
    });
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <div>
          <Tabs
            value={value}
            onChange={(_, val) => setValue(val)}
            variant={isMobile ? "scrollable" : "fullWidth"}
          >
            {member.Program.Sessions &&
              member.Program.Sessions.map((session, index) => (
                <Tab
                  key={`S-${session.Soid}`}
                  label={session.Name}
                  {...a11yProps(index)}
                />
              ))}
            <Tab onClick={addNewSession} label="+" sx={{ maxWidth: "20px" }} />
          </Tabs>
        </div>
      </Box>
      <DraggableDialog
        videoUrl={videoUrl}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        setVideoUrl={setVideoUrl}
      />
      {member.Program.Sessions &&
        member.Program.Sessions.map((session, index) => (
          <TabPanel key={`T-${session.Soid}`} value={value} index={index}>
            <Session
              session={session}
              setVideoUrl={setVideoUrl}
              setTabValue={setValue}
              isFirstSession={index === 0}
              isLastSession={member.Program.Sessions.length === index + 1}
            />
          </TabPanel>
        ))}
      <Tooltip title="Supprimer TOUTES les parties utilisateur">
        <Fab
          color="error"
          aria-label="Delete"
          onClick={deleteAllUserInfos}
          sx={{ position: "fixed", bottom: "70px", right: "20px" }}
        >
          <DeleteSweep />
        </Fab>
      </Tooltip>
      {!member.BilanSent && (
        <Tooltip title="Valider le bilan ?">
          <Fab
            color="primary"
            aria-label="Valider"
            onClick={validateBilan}
            sx={{ position: "fixed", bottom: "70px", right: "100px" }}
          >
            <Check />
          </Fab>
        </Tooltip>
      )}
      <QuestionsDrawer questions={member.Survey.Questions} />
    </>
  );
}
