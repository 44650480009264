import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Select, { MultiValue, SingleValue } from "react-select";
import { useContext, useState } from "react";
import AxiosContext from "../../../../Data/AxiosContext";
import AssetContext from "../../../../Data/AssetsContext";


  export const typeOptions = [
    { label: "Échauffement", value: "Échauffement" },
    { label: "Monoarticulaire", value: "Monoarticulaire" },
    { label: "Global", value: "Global" },
    { label: "Prévention", value: "Prévention" },
    { label: "Étirements", value: "Étirements" },
    { label: "Cardio", value: "Cardio" },
  ];
  export const targetOptions = [
    { label: "Abdominaux", value: "Abdominaux" },
    { label: "Adducteurs", value: "Adducteurs" },
    { label: "Avant-bras", value: "Avant-bras" },
    { label: "Biceps", value: "Biceps" },
    { label: "Bas du corps", value: "Bas du corps" },
    { label: "Dorsaux", value: "Dorsaux" },
    { label: "Trapèzes", value: "Trapèzes" },
    { label: "Coiffe des rotateurs", value: "Coiffe des rotateurs" },
    { label: "Cou", value: "Cou" },
    { label: "Cuisses", value: "Cuisses" },
    { label: "Fessiers", value: "Fessiers" },
    { label: "Épaules", value: "Épaules" },
    { label: "Lombaires", value: "Lombaires" },
    { label: "Ischio-jambiers", value: "Ischio-jambiers" },
    { label: "Haut du corps", value: "Haut du corps" },
    { label: "Jambes", value: "Jambes" },
    { label: "Mollets", value: "Mollets" },
    { label: "Pectoraux", value: "Pectoraux" },
    { label: "Quadriceps", value: "Quadriceps" },
    { label: "Triceps", value: "Triceps" },
  ];

export default function AddNewExercice({
  open,
  setIsOpen,
}: {
  open: boolean;
  setIsOpen: any;
}) {
  const { axiosHelper } = useContext(AxiosContext);
  const { exercices, setExercices } = useContext(AssetContext);
  const [name, setName] = useState("");
  const [type, setType] =
    useState<SingleValue<{ label: string; value: string }>>();
  const [url, setUrl] = useState<string>("");
  const [ytUrl, setYtUrl] = useState("");
  const [target, setTarget] =
    useState<MultiValue<{ label: string; value: string }>>();
  const [hasTimer, setHasTimer] = useState(false);

  const saveNewExercice = () => {
    let targetList: string[] = [];
    target?.forEach((target) => {
      targetList.push(target.value);
    });
    const data: any = {
      HasTimer: hasTimer,
      Name: name,
      Url: url,
      YoutubeUrl: ytUrl,
      Type: type?.value ?? "",
      Target: targetList.join(", "),
    };
    axiosHelper.post(`/api/Assets/Exercice`, data).then(result => {
      let tempExos = [...exercices];
      tempExos.push(result.data);
      setExercices(tempExos);
      setIsOpen(false);
    });
  };

  return (
    <Dialog open={open} onClose={() => setIsOpen(false)}>
      <DialogTitle>Ajouter Nouvel Exercice</DialogTitle>
      <DialogContent>
        <Box>
          <Grid container>
            <Grid item xs={6}>
              <Box p={1}>
                <Typography>Nom</Typography>
                <TextField
                  size="small"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  multiline={true}
                />
              </Box>
              <Box p={1}>
                <Typography>Type</Typography>
                <Select options={typeOptions} onChange={(e) => setType(e)} />
              </Box>
              <Box p={1}>
                <Typography>URL</Typography>
                <TextField
                  size="small"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box p={1}>
                <Typography>YT Url</Typography>
                <TextField
                  size="small"
                  value={ytUrl}
                  onChange={(e) => setYtUrl(e.target.value)}
                />
              </Box>
              <Box p={1}>
                <Typography>Cible</Typography>
                <Select
                  isMulti={true}
                  options={targetOptions}
                  onChange={(e) => setTarget(e)}
                />
              </Box>
              <Box p={1}>
                <Typography>Timer ?</Typography>
                <Checkbox
                  checked={hasTimer}
                  onChange={(e) => setHasTimer(e.target.checked)}
                />
              </Box>
            </Grid>
          </Grid>
          <Box textAlign={"center"}>
            <Button variant="outlined" onClick={saveNewExercice}>
              Sauver
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
