import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { ArrowBack, ExpandMore } from "@mui/icons-material";
import { IUser } from "../../../../Data/Member";
import { useState } from "react";
import Bilan from "./Bilan";
import GraphPoids from "./GraphPoids";
import { isMobile } from "react-device-detect";


export default function MemberSummary({ member }: { member: IUser }) {
  const [isExpanded, setIsExpanded] = useState(!isMobile);
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <IconButton
        onClick={() => {
          window.location.assign("/Administration");
        }}
      >
        <ArrowBack />
      </IconButton>
      <Accordion
        expanded={isExpanded}
        onChange={() => setIsExpanded(!isExpanded)}
        sx={{ width: 1 }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{ backgroundColor: window.location.hostname === "localhost" ? "purple":"#2E4DFC" }}
        >
          <Typography textAlign={"center"} color="white">
            {member.FirstName} {member.LastName}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Paper>
              <Typography textAlign={"center"}>
                {member.FirstName} {member.LastName}
              </Typography>
            </Paper>
          </Box>
          <Grid container spacing={0} textAlign={"center"}>
            <Grid item xs={6}>
              <GraphPoids data={member.Poids} />
            </Grid>
            <Grid item xs={6}>
              <Bilan program={member.Program} />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
