import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { IMeal } from "../../../../Data/Member";
import { useContext } from "react";
import AssetContext from "../../../../Data/AssetsContext";
import { Delete } from "@mui/icons-material";
import IngredientRow from "./IngredientRow";
import AxiosContext from "../../../../Data/AxiosContext";
import UserContext from "../../../../Data/UserContext";
import Swal, { SweetAlertOptions } from "sweetalert2";

export default function Meal({
  meal,
  index,
  daySoid,
}: {
  meal: IMeal;
  index: number;
  daySoid: string;
}) {
  const { ingredients } = useContext(AssetContext);
  const { axiosHelper } = useContext(AxiosContext);
  const { member, setMember } = useContext(UserContext);
  let lipides = 0;
  let glucides = 0;
  let proteines = 0;
  meal.MealItems.forEach((mealItem) => {
    const ingredient = ingredients.find((i) => i.Soid === mealItem.FoodSoid);
    if (ingredient) {
      lipides += ingredient.Lipides * mealItem.Quantity;
      glucides += ingredient.Glucides * mealItem.Quantity;
      proteines += ingredient.Proteines * mealItem.Quantity;
    }
  });

  const deleteMeal = () => {
    let swalOptions: SweetAlertOptions = {
      title: `T'es sur de vouloir supprimer ce repas complet ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      scrollbarPadding: false,
    };
    Swal.fire(swalOptions).then((result) => {
      if (result.isConfirmed) {
        axiosHelper
          .delete(
            `api/Scheduler/Member/${member.Soid}/Day/${daySoid}/Meal/${meal.Soid}/`
          )
          .then(() => {
            const temp = { ...member };
            debugger
            const day = temp.Program.Days.find((d) => d.Soid === daySoid);
            const mealIndex = day?.Meals.findIndex((m) => m.Soid === meal.Soid);
            if (mealIndex !== null && typeof mealIndex !== "undefined" && mealIndex !== -1) {
              day?.Meals.splice(mealIndex, 1);
            }
            setMember(temp);
          });
      }
    });
  };

  const addMealItem = () => {
    axiosHelper
      .post(
        `api/Scheduler/Member/${member.Soid}/Day/${daySoid}/Meal/${meal.Soid}/AddMealItem`
      )
      .then((result) => {
        const newMeal = result.data as IMeal;
        const temp = { ...member };
        const day = temp.Program.Days.find((d) => d.Soid === daySoid);
        if (day) {
          day.Meals = day.Meals.map((x) => {
            if (x.Soid === meal.Soid) {
              return newMeal;
            }
            return x;
          });
        }
        setMember(temp);
      });
  };

  return (
    <Paper
      sx={{
        padding: "8px",
        margin: "8px",
        backgroundColor: "#F2F2F2",
      }}
    >
      <Typography textAlign="center" variant="h5">
        Repas {index + 1}
      </Typography>
      <TableContainer sx={{ margin: "10px" }}>
        <Table size="small">
          <TableHead sx={{ backgroundColor: "lightblue" }}>
            <TableRow>
              <TableCell>Aliment</TableCell>
              <TableCell>Quantité</TableCell>
              <TableCell>Glucides</TableCell>
              <TableCell>Lipides</TableCell>
              <TableCell>Proteines</TableCell>
              <TableCell>Calories</TableCell>
              <TableCell>
                <Button onClick={deleteMeal}>
                  <Delete htmlColor="red" />
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {meal.MealItems.map((item) => {
              return (
                <IngredientRow
                  key={item.Soid}
                  daySoid={daySoid}
                  mealSoid={meal.Soid}
                  item={item}
                />
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell
                colSpan={2}
                sx={{ textAlign: "center" }}
                padding="none"
                rowSpan={2}
              >
                <Button size="small" sx={{ width: 1 }} onClick={addMealItem}>
                  +
                </Button>
              </TableCell>
              <TableCell sx={{ padding: 0 }}>
                <Typography>{glucides.toFixed(2)}</Typography>
              </TableCell>
              <TableCell sx={{ padding: 0 }}>
                <Typography>{lipides.toFixed(2)}</Typography>
              </TableCell>
              <TableCell sx={{ padding: 0 }}>
                <Typography>{proteines.toFixed(2)}</Typography>
              </TableCell>
              <TableCell sx={{ padding: 0 }}>
                {/* <Typography>{(glucides + lipides + proteines).toFixed(2)}</Typography> */}
              </TableCell>
              <TableCell>Grammes</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ padding: 0 }}>
                <Typography color="red">
                  {(glucides * 4).toFixed(2)}
                </Typography>
              </TableCell>
              <TableCell sx={{ padding: 0 }}>
                <Typography color="red">
                  {(lipides * 9).toFixed(2)}
                </Typography>
              </TableCell>
              <TableCell sx={{ padding: 0 }}>
                <Typography color="red">
                  {(proteines * 4).toFixed(2)}
                </Typography>
              </TableCell>
              <TableCell sx={{ padding: 0 }}>
                <Typography color="red">
                  {(glucides * 4 + lipides * 9 + proteines * 4).toFixed(2)}
                </Typography>
              </TableCell>
              <TableCell>Kcal</TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
}
