import { TableRow, TableCell, Button, Checkbox } from "@mui/material";
import { Link } from "react-router-dom";
import { Build, ContentCopy, Delete } from "@mui/icons-material";
import Swal, { SweetAlertOptions } from "sweetalert2";
import { useContext } from "react";
import AxiosContext from "../../Data/AxiosContext";
import { IUser } from "../../Data/Member";

export function MemberRow({
  setCopyMemberSoid,
  row,
  members,
  setMembers,
}: {
  setCopyMemberSoid: any;
  row: IUser;
  members: IUser[];
  setMembers: any;
}) {
  const { axiosHelper } = useContext(AxiosContext);

  const deleteMember = () => {
    let swalOptions: SweetAlertOptions = {
      title: `T'es sur de vouloir supprimer ce membre ? Cette action est irréversible !`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      scrollbarPadding: false,
    };
    Swal.fire(swalOptions).then((result) => {
      if (result.isConfirmed) {
        axiosHelper.delete(`/api/Scheduler/Member/${row.Soid}`).then(() => {
          const temp = [...members];
          const index = temp.findIndex((m) => m.Soid === row.Soid);
          temp.splice(index, 1);
          setMembers(temp);
        });
      }
    });
  };

  const handleCheck = (ev: any, value: any) => {
    const changeRequest: any = {};
    changeRequest.FieldName = "Active";
    changeRequest.Data = value ? "true" : "false";
    axiosHelper
      .post(`/api/Scheduler/Member/${row.Soid}`, changeRequest)
      .then(() => {
        const temp = [...members];
        const member = temp.find((m) => m.Soid === row.Soid);
        if (!member) return;
        member.Active = value;
        setMembers(temp);
      });
  };

  return (
    <TableRow
      sx={{
        backgroundColor: row.BilanSent ? "#ffcccc" : "white",
      }}
    >
      <TableCell>{row.FirstName}</TableCell>
      <TableCell>{row.LastName}</TableCell>
      <TableCell>
        <Checkbox checked={row.Active} onChange={handleCheck} />
      </TableCell>
      <TableCell>
        <Button component={Link} to={`/member/${row.Soid}`}>
          <Build />
        </Button>
      </TableCell>
      <TableCell>
        <Button
          onClick={() => {
            setCopyMemberSoid(row.Soid);
          }}
        >
          <ContentCopy />
        </Button>
      </TableCell>
      <TableCell>
        <Button onClick={deleteMember} color="warning">
          <Delete />
        </Button>
      </TableCell>
    </TableRow>
  );
}
