import { useContext, useState } from "react";
import AssetContext from "../../../../Data/AssetsContext";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import AddNewExercice from "./AddNewExercice";
import EditExoRow from "./EditExoRow";

export default function ExerciceList() {
  const { exercices } = useContext(AssetContext);
  const [editMode, setEditMode] = useState(false);
  const [isAddNewVisible, setIsAddNewVisible] = useState(false);
  return (
    <>
      <Paper sx={{ width: "100%" }}>
        <Box m={"10px"}>
          <Button
            variant="contained"
            sx={{ marginRight: "10px" }}
            onClick={() => setIsAddNewVisible(true)}
          >
            Ajouter
          </Button>
          <FormControlLabel
            control={
              <Switch
                checked={editMode}
                onChange={() => setEditMode(!editMode)}
              />
            }
            label="Mode Modif."
          />
        </Box>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Nom</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Url</TableCell>
                <TableCell>YT Url</TableCell>
                <TableCell>Cible</TableCell>
                <TableCell>Unité</TableCell>
                <TableCell>Timer</TableCell>
              </TableRow>
            </TableHead>
            {exercices && (
              <TableBody>
                {exercices.map(
                  (exo) =>
                    !editMode ? (
                      <TableRow key={exo.Soid}>
                        <TableCell>{exo.Name}</TableCell>
                        <TableCell>{exo.Type}</TableCell>
                        <TableCell>{exo.Url}</TableCell>
                        <TableCell>{exo.YoutubeUrl}</TableCell>
                        <TableCell>{exo.Target}</TableCell>
                        <TableCell>{exo.UnitType}</TableCell>
                        <TableCell>
                          <Checkbox checked={exo.HasTimer} disabled />
                        </TableCell>
                      </TableRow>
                    ) : (
                      <EditExoRow key={`E-${exo.Soid}`} exo={exo} />
                    )
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
      <AddNewExercice open={isAddNewVisible} setIsOpen={setIsAddNewVisible} />
    </>
  );
}
