import {
  ChevronRight,
  Timeline,
  Restore,
  Settings,
  Logout,
  Home,
} from "@mui/icons-material";
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  styled,
} from "@mui/material";
import { useContext } from "react";
import UserContext from "../../Data/UserContext";
import { Link } from "react-router-dom";

export default function SidePanel({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: any;
}) {
  const { member, setMember } = useContext(UserContext);
  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  }));

  const logout = () => {
    localStorage.removeItem("Member");
    setOpen(false);
    setMember(null);
  };

  return (
    <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
      <DrawerHeader>
        <IconButton onClick={() => setOpen(false)}>
          <ChevronRight />
        </IconButton>
        <Typography>
          {member.FirstName} {member.LastName}
        </Typography>
      </DrawerHeader>
      <List>
        <Link
          to="/"
          style={{ textDecoration: "none", color: "black", width: "100%" }}
          onClick={() => setOpen(false)}
        >
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <Home />
              </ListItemIcon>
              <ListItemText primary={"Appli"} />
            </ListItemButton>
          </ListItem>
        </Link>
      </List>
      <Divider />
      <List>
        <ListItem disablePadding>
          <Link
            to="/Courbe"
            style={{ textDecoration: "none", color: "black", width: "100%" }}
            onClick={() => setOpen(false)}
          >
            <ListItemButton>
              <ListItemIcon>
                <Timeline />
              </ListItemIcon>
              <ListItemText primary={"Courbe Poids"} />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem disablePadding>
          <Link
            to="/History"
            style={{ textDecoration: "none", color: "black", width: "100%" }}
            onClick={() => setOpen(false)}
          >
            <ListItemButton>
              <ListItemIcon>
                <Restore />
              </ListItemIcon>
              <ListItemText primary={"Historique"} />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem disablePadding>
          <Link
            to="/Settings"
            style={{ textDecoration: "none", color: "black", width: "100%" }}
            onClick={() => setOpen(false)}
          >
            <ListItemButton>
              <ListItemIcon>
                <Settings />
              </ListItemIcon>
              <ListItemText primary={"Paramètres"} />
            </ListItemButton>
          </Link>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem disablePadding>
          <Link
            to="/"
            style={{ textDecoration: "none", color: "black", width: "100%" }}
            onClick={logout}
          >
            <ListItemButton>
              <ListItemIcon>
                <Logout />
              </ListItemIcon>
              <ListItemText primary={"Se déconnecter"} />
            </ListItemButton>
          </Link>
        </ListItem>
      </List>
    </Drawer>
  );
}
