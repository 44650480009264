import { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { IUser } from "../../Data/Member";
import SignIn from "../Common/SignIn";
import UserContext from "../../Data/UserContext";
import AxiosContext from "../../Data/AxiosContext";
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import { Menu } from "@mui/icons-material";
import SidePanel from "./SidePanel";

function LoadingScreen() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <img src="Logo.jpg" alt="Loading" />
    </Box>
  );
}

export default function Home() {
  const { axiosHelper } = useContext(AxiosContext);
  const [isLoading, setIsLoading] = useState(true); // Add the loading state
  const [member, setMember] = useState<IUser | null>(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (typeof localStorage.Member !== "undefined") {
      const currentSoid = localStorage.Member;
      setIsLoading(true);
      axiosHelper(`/api/Scheduler/Members/${currentSoid}/Clean`)
        .then((response) => {
          if (response.data.Active === false) {
            localStorage.removeItem("Member");
            setIsLoading(false);
            return;
          }
          setMember(response.data);
          setIsLoading(false);
        })
        .catch(() => {
          localStorage.removeItem("Member");
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [axiosHelper]);

  if (isLoading) {
    return <LoadingScreen />;
  } else if (member) {
    return (
      <UserContext.Provider
        value={{
          member: member,
          setMember: setMember,
        }}
      >
        <AppBar
          position="static"
          sx={{
            background:
              window.location.hostname === "localhost"
                ? `linear-gradient(70deg, #4899ea 10%,#b30059 100%)` //Test
                : `linear-gradient(70deg, #4899ea 10%,#1976d2 100%)`, //Live
          }}
          color={
            window.location.hostname === "localhost" ? "primary" : "primary"
          }
        >
          <Toolbar variant="dense">
            <Box sx={{ flexGrow: 1 }}>
              <Typography
                fontStyle="italic"
                fontFamily="fantasy"
                variant="h5"
                sx={{ color: "#0d4072" }}
              >
                {/* {window.location.hostname === "localhost"
                  ? "Valeo - Test"
                  : "VALEOPERFORMANCE"} */}
              </Typography>
            </Box>
            <Box sx={{ display: { md: "flex" } }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={() => setOpen(true)}
                sx={{ ...(open && { display: "none" }) }}
              >
                <Menu />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        <Outlet />
        <SidePanel open={open} setOpen={setOpen} />
      </UserContext.Provider>
    );
  } else {
    return (
      <SignIn axiosHelper={axiosHelper} setMember={setMember} realm="User" />
    );
  }
}
