import { useContext } from "react";
import UserContext from "../../../Data/UserContext";
import GraphPoids from "../../Admin/Member/Summary/GraphPoids";
import { Box, Container, Grid, Typography } from "@mui/material";
import moment from "moment";

export default function Courbe() {
    const { member } = useContext(UserContext);

  return (
    <Container>
      <Typography variant="h4" align="center">
        Courbe de poids
      </Typography>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Box></Box>
          <GraphPoids data={member.Poids} />
        </Grid>
        <Grid item xs={12} md={6} textAlign={"center"}>
          {member.Poids.slice(-10).map((item, index) => (
            <Box key={item.Soid}>
              <Typography>
                {moment(item.Date).format("DD/MM/YYYY")} - {item.Poids}
              </Typography>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
}
