import { Box, Button, Typography } from "@mui/material";
import { ArrowBack, Close } from "@mui/icons-material";
import { useState } from "react";
import { IQuestion } from "../../../Data/Member";

export default function QuestionsDrawer({questions}: {questions: IQuestion[]}) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  return (
    <>
      <Button
        color="primary"
        aria-label="open drawer"
        variant="contained"
        onClick={() => setDrawerOpen(true)}
        sx={{
          position: "fixed",
          right: "0",
          top: "50%",
          transform: "translateY(-50%)",
          width: "15px",
          // Adjust the width as needed
          height: "100px",
          minWidth: "15px",
          // This ensures the button does not have a minimum width
          padding: "0",
        }}
      >
        <ArrowBack />
      </Button>

      <Box
        sx={{
          position: "fixed",
          right: drawerOpen ? 0 : "-300px",
          // Controls the drawer position
          top: "0",
          bottom: "55px",
          width: "300px",
          background: "whitesmoke",
          transition: "right 0.3s",
          overflowY: "auto",
          // Enable scrolling if content overflows
          zIndex: 1200,
          // Ensure the drawer appears above other content
          borderTopLeftRadius: "20px",
        }}
      >
        <Box
          sx={{
            position: "sticky",
            top: 0,
            background: "whitesmoke",
            zIndex: 2,
          }}
        >
          <Button onClick={() => setDrawerOpen(false)}>
            <Close />
          </Button>
        </Box>
        <Box p={1}>
          {questions.map((q) => (
            <Box key={q.Soid}>
              <Typography
                textAlign="center"
                variant="body1"
                sx={{
                  textDecoration: "underline",
                }}
              >
                {q.Quest}
              </Typography>
              <Typography textAlign="center">{q.Reponse}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
}
