import {
  Box,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import UserContext from "../../../../Data/UserContext";
import moment from "moment";
import { IProgram } from "../../../../Data/Member";
import DraggableDialog from "../Entrainement/DraggableDialog";
import SessionRO from "./SessionRO";
import { TabPanel } from "../TabPanel";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function History() {
  const { member } = useContext(UserContext);
  const [program, setProgram] = useState<IProgram | null>(null);
  const [selectValue, setSelectValue] = useState<string>("");
  const [tabValue, setTabValue] = useState<number>(0);
  const [videoUrl, setVideoUrl] = useState<string>("");
  const [openDialog, setOpenDialog] = useState(false);

  const handleChange = (e: SelectChangeEvent) => {
    const soid = e.target.value;
    setSelectValue(soid);
    if (soid && soid !== "") {
      var selected = member.ArchivedPrograms.find((p) => p.Soid === soid);
      if (selected) setProgram(selected);
    }
  };

    useEffect(() => {
      if (videoUrl !== "") setOpenDialog(true);
    }, [videoUrl]);

  return (
    <Container>
      <Box textAlign="center">
        <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
          <InputLabel>Programme</InputLabel>
          <Select onChange={handleChange} value={selectValue}>
            <MenuItem value="" />
            {member.ArchivedPrograms.map((prog) => (
              <MenuItem key={prog.Soid} value={prog.Soid}>
                {moment(prog.CreatedOn).format("DD/MM/YYYY")}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Tabs value={tabValue} onChange={(e, val) => setTabValue(val)}>
              {program?.Sessions &&
                program.Sessions.map((session, index) => (
                  <Tab
                    key={`S-${session.Soid}`}
                    label={session.Name}
                    {...a11yProps(index)}
                  />
                ))}
            </Tabs>
          </div>
        </Box>
        <DraggableDialog
          videoUrl={videoUrl}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          setVideoUrl={setVideoUrl}
        />
        {program?.Sessions &&
          program.Sessions.map((session, index) => (
            <TabPanel key={`T-${session.Soid}`} value={tabValue} index={index}>
              <SessionRO
                memberSoid={member.Soid}
                session={session}
                setVideoUrl={setVideoUrl}
              />
            </TabPanel>
          ))}
      </Box>
    </Container>
  );
}
