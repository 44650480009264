import { useState, useEffect, useContext } from "react";
import { IUser } from "../../Data/Member";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Container,
  Box,
  FormControlLabel,
  Switch,
} from "@mui/material";
import AxiosContext from "../../Data/AxiosContext";
import { Add } from "@mui/icons-material";
import AddNewMember from "./AddNewMember";
import { PopupCopySession } from "./PopupCopySession";
import { MemberRow } from "./MemberRow";

export default function MemberList() {
  const [members, setMembers] = useState<IUser[]>([]);
  const { axiosHelper } = useContext(AxiosContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [copyMemberSoid, setCopyMemberSoid] = useState<string | null>(null);
  const [showInactive, setShowInactive] = useState<boolean>(false);

  useEffect(() => {
    axiosHelper("/api/Scheduler/Members/MemberList").then((result) => {
      setMembers(result.data);
    });
  }, [axiosHelper]);

  return (
    <Container>
      <Box>
        <Button
          title="Ajouter"
          variant="contained"
          onClick={() => setIsOpen(true)}
        >
          <Add />
          Ajouter
        </Button>
        <FormControlLabel
          control={
            <Switch
              checked={showInactive}
              onChange={() => setShowInactive(!showInactive)}
            />
          }
          label="Voir Inactif"
          sx={{ pl: "15px" }}
        />
      </Box>
      <TableContainer component={Paper} sx={{ marginTop: "5px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>Prénom</TableCell>
              <TableCell>Nom</TableCell>
              <TableCell>Actif</TableCell>
              <TableCell>Programme</TableCell>
              <TableCell>Copier</TableCell>
              <TableCell>Supprimer</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {members &&
              members.map( row => {
                if (showInactive) {
                  if (!row.Active) {
                    return (
                      <MemberRow
                        key={row.Soid}
                        setCopyMemberSoid={setCopyMemberSoid}
                        row={row}
                        members={members}
                        setMembers={setMembers}
                      />
                    );
                  }else return null;
                } else {
                  if (row.Active) {
                    return (
                      <MemberRow
                        key={row.Soid}
                        setCopyMemberSoid={setCopyMemberSoid}
                        row={row}
                        members={members}
                        setMembers={setMembers}
                      />
                    );
                  } else return null;
                }
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <PopupCopySession
        copyMemberSoid={copyMemberSoid}
        setCopyMemberSoid={setCopyMemberSoid}
      />
      <AddNewMember
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setMembers={setMembers}
        members={members}
      />
    </Container>
  );
}
