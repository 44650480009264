import React from "react";
import { IUser, IProgram, IPoids } from "./Member";

interface DefaultUserState {
  member: IUser;
  setMember: React.Dispatch<React.SetStateAction<IUser | null>>;
}

const defaultUserState: DefaultUserState = {
  member: {
    Soid: "",
    FirstName: "",
    LastName: "",
    UserName: "",
    Password: "",
    BirthDate: "",
    EmailAddress: "",
    Notes: "",
    SubscribedUntil: new Date(),
    Active: false,
    BilanSent: false,
    Survey: {
      Etudes: "",
      Parcours: "",
      Travail: "",
      Poids: 0,
      Taille: 0,
      FoodQuestions: [],
      Questions: [],
    },
    Program: {} as IProgram,
    Poids: [] as IPoids[],
    ArchivedPrograms: [] as IProgram[],
  },
  setMember: () => {}, // It's an empty function for now
};

const UserContext = React.createContext<DefaultUserState>(defaultUserState);
export default UserContext;
