import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function DraggableDialog({ videoUrl, openDialog, setOpenDialog, setVideoUrl}: { videoUrl: string, openDialog:boolean, setOpenDialog:any, setVideoUrl:any }) {

  const handleClose = () => {
    console.log(";")
    setVideoUrl("")
    setOpenDialog(false);
  };

  return (
    <div>
      <Dialog
        open={openDialog}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          
        </DialogTitle>
        <DialogContent>
          <video width="500" height={"400"} controls src={videoUrl} />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}