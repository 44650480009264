import { useContext, useEffect, useState } from "react";
import Select, { components } from "react-select";
import { Exercice } from "../../../../Data/Assets";
import AssetContext from "../../../../Data/AssetsContext";

type ExerciseGroup = {
  [key: string]: {
    value: string;
    label: string;
    color: string;
  }[];
};

export default function ExoDropDown({
  exerciceSoid,
  changeExercice,
}: {
  exerciceSoid: string;
  changeExercice: any;
}) {
  const [options, setOptions] = useState<
    {
      label: string;
      options: { value: string; label: string; color: string }[];
    }[]
  >([]);
  const [selectedOption, setSelectedOption] = useState<{
    value: string;
    label: string;
    color: string;
  } | null>(null);
  const { exercices } = useContext(AssetContext);

  useEffect(() => {
    const sortedData: ExerciseGroup = {};
    exercices.forEach((item: Exercice) => {
      let color = "black";
      switch (item.Type) {
        case "Échauffement":
          color = "lightblue";
          break;
        case "Monoarticulaire":
          color = "red";
          break;
        case "Global":
          color = "blue";
          break;
        case "Prévention":
          color = "green";
          break;
        case "Étirements":
          color = "brown";
          break;
        case "Cardio":
          color = "fuchsia";
          break;
        default:
          break;
      }
      const targets = item.Target.split(",");
      targets.forEach((target: string) => {
        const trimmedTarget = target.trim();
        if (!sortedData[trimmedTarget]) {
          sortedData[trimmedTarget] = [];
        }
        const option = {
          value: item.Soid,
          label: item.Name,
          color: color,
        };
        sortedData[trimmedTarget].push(option);

        // Check if the item name matches the default exercise name
        if (item.Soid === exerciceSoid) {
          setSelectedOption(option);
        }
      });
    });

    // Convert sortedData to an array of options
    const newOptions = Object.entries(sortedData).map(([label, options]) => ({
      label,
      options,
    }));

    setOptions(newOptions);
  }, [exercices, exerciceSoid]);

  // Custom option component
  const Option = (props: any) => {
    return (
      <components.Option {...props}>
        <div style={{ color: props.data.color }}>{props.label}</div>
      </components.Option>
    );
  };

  const SingleValue = (props: any) => (
    <components.SingleValue {...props}>
      <div style={{ color: props.data.color }}>{props.data.label}</div>
    </components.SingleValue>
  );

  return (
    <div style={{ minWidth: "300px" }}>
      <Select
        value={selectedOption}
        components={{ Option, SingleValue }}
        options={options}
        onChange={(e: any) => {
          changeExercice(e.value);
          setSelectedOption(e);
        }}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          control: (base) => ({ ...base, fontSize: "20px"}),
        }}
      />
    </div>
  );
}
