import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AxiosInstance } from "axios";
import { useState } from "react";

const theme = createTheme();

export default function SignIn({
  axiosHelper,
  setMember,
  setIsLoggedIn,
  realm,
}: {
  axiosHelper: AxiosInstance;
  setMember?: any;
  setIsLoggedIn?: any;
  realm: string;
}) {
  const [isError, setIsError] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsError(false);
    const data = new FormData(event.currentTarget);
    const loginInfo: any = {};
    loginInfo.UserName = data.get("email");
    loginInfo.Password = data.get("password");
    switch (realm) {
      case "User":
        axiosHelper
          .post("/api/Scheduler/Members/Login", loginInfo)
          .then((result) => {
            localStorage.Member = result.data.Soid;
            setMember(result.data);
          })
          .catch((error) => {
            setIsError(true);
            setError(error.response.data);
          });
        break;
      case "Admin":
        axiosHelper
          .post("/api/Admin/Users/Login", loginInfo)
          .then((result) => {
            localStorage.User = result.data;
            setIsLoggedIn(result.data);
          })
          .catch(() => {
            setIsError(true);
          });
        break;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Connexion
          </Typography>
          {isError && (
            <Typography component="h1" variant="h5" color={"red"}>
              Erreur ! {error}
            </Typography>
          )}
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Nom d'utilisateur"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Mot de passe"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Connexion
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
