import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import { isMobile } from "react-device-detect";

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: isMobile ? 0 : "normal", // Set padding to 0 if mobile, else use default
        },
      },
    },
  },
});

export default function TotalSummary({
  glucides,
  lipides,
  proteines,
  global,
}: {
  glucides: number;
  lipides: number;
  proteines: number;
  global?: boolean;
}) {
  return (
    <Paper
      sx={{
        padding: "8px",
        margin: "8px",
        backgroundColor: global === true ? "#ffd1da" : "lightblue",
      }}
    >
      {global && (
        <Typography align="center" color="red" variant="h4">
          Total hebdomadaire
        </Typography>
      )}
      <ThemeProvider theme={theme}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Glucides</TableCell>
                <TableCell>Lipides</TableCell>
                <TableCell>Proteines</TableCell>
                <TableCell>Total</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography>{glucides.toFixed(2)}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{lipides.toFixed(2)}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{proteines.toFixed(2)}</Typography>
                </TableCell>
                <TableCell>
                  {/* <Typography>
                    {(lipides + glucides + proteines).toFixed(2)}
                  </Typography> */}
                </TableCell>
                <TableCell>Grammes</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography color="red" fontWeight="bold">
                    {(glucides * 4).toFixed(2)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="red" fontWeight="bold">
                    {(lipides * 9).toFixed(2)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="red" fontWeight="bold">
                    {(proteines * 4).toFixed(2)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="red" fontSize="16px" fontWeight="bold">
                    {(lipides * 9 + glucides * 4 + proteines * 4).toFixed(2)}
                  </Typography>
                </TableCell>
                <TableCell>Kcal</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </ThemeProvider>
    </Paper>
  );
}
