import React, { useEffect, useRef } from "react";
import {
  Chart,
  LinearScale,
  LineController,
  PointElement,
  LineElement,
  CategoryScale,
  Tooltip,
} from "chart.js";
import { IPoids } from "../../../../Data/Member";
import moment from "moment";

Chart.register(
  LinearScale,
  LineController,
  PointElement,
  LineElement,
  CategoryScale,
  Tooltip
);

interface ChartProps {
  data: IPoids[];
}

export default function GraphPoids({ data }: ChartProps) {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstance = useRef<Chart | null>(null); // hold a ref to the chart instance

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext("2d");
      const cleanedData = data.filter(i => i.Poids !== 0)
      if (ctx) {
        // if a chart instance already exists, destroy it before creating a new one
        if (chartInstance.current) {
          chartInstance.current.destroy();
          chartInstance.current = null;
        }

        chartInstance.current = new Chart(ctx, {
          type: "line",
          data: {
            labels: cleanedData.map((point: any) =>
              moment(point.Date).format("DD/MM")
            ),
            datasets: [
              {
                label: "Poids",
                data: cleanedData.map((point: any) => point.Poids),
                borderColor: "rgb(75, 192, 192)",
                fill: false,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
          },
        });
      }
    }
  }, [data]);

  return <canvas ref={chartRef} />;
}
