import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IUser } from "../../../Data/Member";
import {
  Box,
  Container,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  Typography,
} from "@mui/material";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import DescriptionIcon from "@mui/icons-material/Description";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import EntrainementList from "./Entrainement/EntrainementList";
import DaysList from "./Repas/DaysList";
import MemberSummary from "./Summary/MemberSummary";
import Edit from "./Edit";
import AxiosContext from "../../../Data/AxiosContext";
import UserContext from "../../../Data/UserContext";
import { Restore } from "@mui/icons-material";
import History from "./History/History";

export default function Member() {
  const { axiosHelper } = useContext(AxiosContext);
  const [isLoggedIn, setIsLoggedIn] = useState<string | null>(null);
  const { id } = useParams();
  const [member, setMember] = useState<IUser | null>(null);
  const [value, setValue] = useState(
    window.location.hash.substr(1) || "Entrainement"
  );

  useEffect(() => {
    axiosHelper(`/api/Scheduler/Members/${id}/Clean`).then((result) => {
      setMember(result.data);
    });
  }, [axiosHelper, id]);

  useEffect(() => {
    if (typeof localStorage.User !== "undefined" && localStorage.User !== "")
      setIsLoggedIn(localStorage.User);
  }, []);

  const handleNavigationChange = (
    event: React.ChangeEvent<{}>,
    newValue: string
  ) => {
    window.location.hash = newValue;
    setValue(newValue);
  };

  if (isLoggedIn) {
    return (
      member && (
        <UserContext.Provider
          value={{
            member: member,
            setMember: setMember,
          }}
        >
          <Container maxWidth={false}>
            <Box sx={{ width: "100%" }}>
              <MemberSummary member={member} />
              <Box sx={{paddingBottom: "60px"}}>
                {value === "Entrainement" && <EntrainementList />}
                {value === "Repas" && <DaysList />}
                {value === "Edit" && <Edit />}
                {value === "History" && <History />}
              </Box>
              <Paper
                sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
                elevation={3}
              >
                <BottomNavigation
                  showLabels
                  value={value}
                  onChange={handleNavigationChange}
                >
                  <BottomNavigationAction
                    value="Entrainement"
                    label="Entrainement"
                    icon={<FitnessCenterIcon />}
                  />
                  <BottomNavigationAction
                    value="Repas"
                    label="Repas"
                    icon={<RestaurantIcon />}
                  />
                  <BottomNavigationAction
                    value="Edit"
                    label="Edit"
                    icon={<DescriptionIcon />}
                  />
                  <BottomNavigationAction
                    value="History"
                    label="History"
                    icon={<Restore />}
                  />
                </BottomNavigation>
              </Paper>
            </Box>
          </Container>
        </UserContext.Provider>
      )
    );
  } else {
    return <Typography>You are not logged in</Typography>;
  }
}
