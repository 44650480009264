import { useContext, useRef, useState } from "react";
import { IRoutine } from "../../../Data/Member";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VideocamIcon from "@mui/icons-material/Videocam";
import Typography from "@mui/material/Typography";
import Routine from "./Routine";
import AssetContext from "../../../Data/AssetsContext";
import Timer from "./Timer";

export function SessionAccordion({
  routines,
  sessionSoid,
  value,
}: {
  routines: IRoutine[];
  sessionSoid: string;
  value: string;
}) {
  const { exercices } = useContext(AssetContext);
  const [expanded, setExpanded] = useState<string | null>(null);
  const [timer, setTimer] = useState(0);
  const [isTimerDisplayed, setIsTimerDisplayed] = useState(false);

  const accordionRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  routines.forEach((routine) => {
    if (!accordionRefs.current[routine.Soid]) {
      accordionRefs.current[routine.Soid] = null;
    }
  });

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : null);
      if (newExpanded && accordionRefs.current[panel]) {
        setTimeout(() => {
          accordionRefs.current[panel]?.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }, 800); // Increase the delay if needed
      }
    };

  return (
    <div
      role="tabpanel"
      hidden={value !== sessionSoid}
      id={`simple-tabpanel-${sessionSoid}`}
      aria-labelledby={`simple-tab-${sessionSoid}`}
    >
      {routines &&
        routines.map((routine) => (
          <Accordion
            key={routine.Soid}
            elevation={2}
            expanded={expanded === routine.Soid}
            onChange={handleChange(routine.Soid)}
            ref={(ref) => (accordionRefs.current[routine.Soid] = ref)} // Add the ref here
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              sx={{
                background:
                  routine.Note === 0
                    ? `linear-gradient(70deg, #ffe6e6 10%,#ffb3b3 100%)` // Rouge
                    : `linear-gradient(70deg, #e6f0ff 10%,#b3d1ff 100%)`, // Bl
                color: routine.Note === 0 ? "#800000" : "#0c63e4",
              }}
            >
              <Typography mr={1}>
                {exercices.find((e) => e.Soid === routine.ExerciceSoid)?.Name}
              </Typography>
              {routine.VideoNeeded ? <VideocamIcon /> : ""}
            </AccordionSummary>
            <AccordionDetails>
              <Routine
                expanded={expanded === routine.Soid}
                routine={routine}
                sessionSoid={sessionSoid}
                setTimer={setTimer}
                setIsTimerDisplayed={setIsTimerDisplayed}
              />
            </AccordionDetails>
          </Accordion>
        ))}
      <Timer
        timerSeconds={timer}
        setTimerSeconds={setTimer}
        isDisplayed={isTimerDisplayed}
        setIsTimerDisplayed={setIsTimerDisplayed}
      />
    </div>
  );
}
