import { useContext, useState } from "react";
import AssetContext from "../../../../Data/AssetsContext";
import {
  Box,
  Button,
  FormControlLabel,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import AddNewIngredient from "./AddNewIngredient";
import EditFoodRow from "./EditFoodRow";

export default function IngredientList() {
  const { ingredients } = useContext(AssetContext);
  const [editMode, setEditMode] = useState(false);
  const [isAddNewVisible, setIsAddNewVisible] = useState(false);

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <Box m={"10px"}>
          <Button
            variant="contained"
            sx={{ marginRight: "10px" }}
            onClick={() => setIsAddNewVisible(true)}
          >
            Ajouter
          </Button>
          <FormControlLabel
            control={
              <Switch
                checked={editMode}
                onChange={() => setEditMode(!editMode)}
              />
            }
            label="Mode Modif."
          />
        </Box>
        <TableContainer>
          <Table size="small" stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Nom</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Glucides</TableCell>
                <TableCell>Lipides</TableCell>
                <TableCell>Protéines</TableCell>
              </TableRow>
            </TableHead>
            {!editMode && (
              <TableBody>
                {ingredients &&
                  ingredients.map((ingredient) => (
                    <TableRow key={ingredient.Soid}>
                      <TableCell>{ingredient.Name}</TableCell>
                      <TableCell>{ingredient.Type}</TableCell>
                      <TableCell>{ingredient.Glucides}</TableCell>
                      <TableCell>{ingredient.Lipides}</TableCell>
                      <TableCell>{ingredient.Proteines}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
            {editMode && (
              <TableBody>
                {ingredients &&
                  ingredients.map((ingredient) => (
                    <EditFoodRow key={`E-${ingredient.Soid}`} ingredient={ingredient} />
                  ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
      <AddNewIngredient open={isAddNewVisible} setIsOpen={setIsAddNewVisible} />
    </>
  );
}
