import { Tabs, Tab, Box } from "@mui/material";
import { useState } from "react";
import ExerciceList from "./Assets/Exercices/ExerciceList";
import IngredientList from "./Assets/Food/IngredientList";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}
export default function Assets() {
  const [value, setValue] = useState<number>(0);

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        ></div>
        <Tabs value={value} onChange={(e, val) => setValue(val)}>
          <Tab label="Exercices" {...a11yProps(0)} />
          <Tab label="Ingredients" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <ExerciceList />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <IngredientList />
      </TabPanel>
    </>
  );
}
