import { Container, Typography } from "@mui/material";

export default function InProgress(){
    return(
        <Container>
            <Typography variant="h4" align="center">
                En Travaux ! Revenez plus tard !
            </Typography>
        </Container>
    )
}