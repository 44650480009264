import { useContext, useState } from "react";
import { Container, TextField, Button, Grid, Typography, Box } from "@mui/material";
import AxiosContext from "../../Data/AxiosContext";
import { IQuestion, IUser } from "../../Data/Member";

function isNullOrEmpty(str:string) {
  return str === null || str === undefined || str === "";
}

export default function Survey() {
  const { axiosHelper } = useContext(AxiosContext);
  const [success, setSuccess] = useState<boolean>(false);
  const [member, setMember] = useState<IUser>({
    Survey: { Questions: [] as IQuestion[], FoodQuestions: [] as IQuestion[] },
  } as IUser);

  const handleSubmit = () => {
    if(isNullOrEmpty(member.Password)){
      alert('Champ "Mot de passe" obligatoire !');
      return;
    }
    if(isNullOrEmpty(member.FirstName)){
      alert('Champ "Prénom" obligatoire !');
      return;
    }
    if(isNullOrEmpty(member.LastName)){
      alert('Champ "Nom" obligatoire !');
      return;
    }
    if(isNullOrEmpty(member.EmailAddress)){
      alert('Champ "Email" obligatoire !');
      return;
    }
    axiosHelper
      .post("/api/Scheduler/Members/SaveSurveyMember", member)
      .then(() => {
        setSuccess(true);
      });
  };

  const handleQuestion = (event: any, type: string) => {
    const question = event.target.labels[0].innerText;
    const value = event.target.value;

    setMember((prevMember) => {
      if (type === "Base") {
        const existing = prevMember.Survey.Questions.find(
          (q) => q.Quest === question
        );
        if (!existing) {
          const newQuestion = {} as IQuestion;
          newQuestion.Quest = question;
          newQuestion.Reponse = value;
          prevMember.Survey.Questions.push(newQuestion);
          prevMember.Survey.Questions.sort((a, b) =>
            a.Quest.localeCompare(b.Quest)
          );
        } else {
          existing.Reponse = value;
        }
      } else {
        const existing = prevMember.Survey.FoodQuestions.find(
          (q) => q.Quest === question
        );
        if (!existing) {
          const newQuestion = {} as IQuestion;
          newQuestion.Quest = question;
          newQuestion.Reponse = value;
          prevMember.Survey.FoodQuestions.push(newQuestion);
          prevMember.Survey.FoodQuestions.sort((a, b) =>
            a.Quest.localeCompare(b.Quest)
          );
        } else {
          existing.Reponse = value;
        }
      }

      return prevMember;
    });
  };

  const handleChange = (data: string, field: string) => {
    const temp = { ...member };
    switch (field) {
      case "FirstName":
        temp.FirstName = data;
        break;
      case "LastName":
        temp.LastName = data;
        break;
      case "EmailAddress":
        temp.EmailAddress = data;
        break;
      case "Password":
        temp.Password = data;
        break;
      case "BirthDate":
        temp.BirthDate = data;
        break;
      case "Poids":
        temp.Survey.Poids = parseFloat(data);
        break;
      case "Taille":
        temp.Survey.Taille = parseFloat(data);
        break;
      case "Etudes":
        temp.Survey.Etudes = data;
        break;
      case "Parcours":
        temp.Survey.Parcours = data;
        break;
      case "Travail":
        temp.Survey.Travail = data;
        break;
      default:
        break;
    }
    setMember(temp);
  };

  if (!success) {
    return (
      <Container>
        <Typography variant="h3" textAlign="center">
          Questionnaire
        </Typography>
        <fieldset>
          <legend>Section 1/3: Présentation</legend>
          <Grid container spacing={2} p={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Nom"
                variant="outlined"
                fullWidth
                onChange={(ev) => handleChange(ev.target.value, "LastName")}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Prénom"
                variant="outlined"
                fullWidth
                onChange={(ev) => handleChange(ev.target.value, "FirstName")}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Date de Naissance"
                variant="outlined"
                fullWidth
                onChange={(ev) => handleChange(ev.target.value, "BirthDate")}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} p={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                onChange={(ev) => handleChange(ev.target.value, "EmailAddress")}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Mot de passe"
                variant="outlined"
                fullWidth
                onChange={(ev) => handleChange(ev.target.value, "Password")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Poids"
                variant="outlined"
                fullWidth
                type="number"
                onChange={(ev) =>
                  handleChange(ev.target.value.toString(), "Poids")
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Taille (en cm)"
                variant="outlined"
                fullWidth
                type="number"
                onChange={(ev) =>
                  handleChange(ev.target.value.toString(), "Taille")
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} p={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Études"
                variant="outlined"
                fullWidth
                multiline
                minRows={2}
                onChange={(ev) => handleChange(ev.target.value, "Etudes")}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Parcours"
                variant="outlined"
                fullWidth
                multiline
                minRows={2}
                onChange={(ev) => handleChange(ev.target.value, "Parcours")}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Travail"
                variant="outlined"
                fullWidth
                multiline
                minRows={2}
                onChange={(ev) => handleChange(ev.target.value, "Travail")}
              />
            </Grid>
          </Grid>
        </fieldset>
        <fieldset>
          <legend style={{ marginBottom: "10px" }}>
            Section 2/3: Questions
          </legend>
          <Grid container spacing={2}>
            {[
              "01. Comment te définis-tu ?",
              "02. Quel est le problème numéro un que tu rencontres lors de tes entraînements sportifs ?",
              "03. Quels sont tes objectifs sportifs et quel est l'objectif principal ?",
              "04. Quel est ton obstacle principal selon toi pour réussir ?",
              "05. Quels sont tes objectifs personnels ?",
              "06. Quelles sont tes motivations ?",
              "07. Selon toi, quelles sont tes qualités ?",
              "08. Dans quel(s) domaine(s) te définirais-tu comme fort(e) ou excellent(e) ?",
              "09. Quelles sont tes valeurs ?",
              "10. Pour quand sont tes objectifs sportifs ?",
              "11. Comment décrirais-tu ton mode de vie ? (Intense, actif, sédentaire) ?",
              "12. Quels sont les activités sportives que tu as pratiquées ? Pendant combien de temps ? À quel niveau ?",
              "13. Quelles sont les blessures que tu as eues ? Et celles que tu as actuellement ?",
              "14. À quoi je peux te servir précisément ? (Plan alimentaire, entraînement, correction technique des mouvements, etc...)",
              "15. De quels moyens matériels disposes-tu pour t'entraîner ?",
              "16. À quelle fréquence souhaites-tu t'entraîner ?",
              "17. Quels sont les exercices que tu ne veux absolument pas faire ?",
              "18. À quoi ressemble ton programme d'entraînement et ton plan alimentaire actuellement ?",
            ].map((label, index) => (
              <Grid item xs={12} key={index}>
                <QuestionTextField
                  label={label}
                  onChange={handleQuestion}
                  category="Base"
                />
              </Grid>
            ))}
          </Grid>
        </fieldset>
        <fieldset>
          <legend style={{ marginBottom: "10px" }}>
            Section 3/3: Alimentation
          </legend>
          <Grid container spacing={2}>
            {[
              "01. Que prends tu au petit déjeuner  ?",
              "02. Que prends tu au Goûter du matin   ?",
              "03. Que prends tu aux repas du midi et du soir ?",
              "04. Que prends au goûter de l'après-midi ?",
              "05. Quel sont les plats que tu aimes manger?",
              "06. Quel sont les aliments que tu ne veux absolument pas manger?",
              "07. Fais moi une journée type des menus que tu consommes",
            ].map((label, index) => (
              <Grid item xs={12} key={index}>
                <QuestionTextField
                  label={label}
                  onChange={handleQuestion}
                  category="Food"
                />
              </Grid>
            ))}
          </Grid>
        </fieldset>
        <Box textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{ margin: "20px" }}
          >
            Envoyer
          </Button>
        </Box>
      </Container>
    );
  } else {
    return <Typography variant="h3">Questionnaire envoyé !</Typography>;
  }
}

function QuestionTextField({ label, onChange, category }: {label: string, onChange: any, category: string}) {
  return (
    <TextField
      label={label}
      variant="outlined"
      fullWidth
      multiline
      rows={4}
      onChange={(ev) => onChange(ev, category)}
      InputLabelProps={{
        sx: {
          whiteSpace: "normal",
          display: "block",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "90%",
          lineHeight: "0.9",
        },
      }}
    />
  );
}