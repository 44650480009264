import { Box, Button, TextField } from "@mui/material";
import { IPoids } from "../../../Data/Member";
import { useContext, useState } from "react";
import moment from "moment";
import AxiosContext from "../../../Data/AxiosContext";
import { Delete } from "@mui/icons-material";
import Swal, { SweetAlertOptions } from "sweetalert2";
import UserContext from "../../../Data/UserContext";

export default function EditPoids({
  poids,
  memberSoid,
}: {
  poids: IPoids;
  memberSoid: string;
}) {
  const { axiosHelper } = useContext(AxiosContext);
  const { member, setMember } = useContext(UserContext);
  const [weight, setWeight] = useState(poids.Poids);
  const update = (e: any) => {
    setWeight(e.target.value);
    var data: any = {};
    data.Data = String(e.target.value);
    axiosHelper
      .patch(`api/Scheduler/Member/${memberSoid}/Weight/${poids.Soid}`, data)
      .then((result) => {
        console.log(result);
      });
  };

  const deletePoids = () => {
    let swalOptions: SweetAlertOptions = {
      title: `T'es sur de vouloir supprimer ce Poids ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      scrollbarPadding: false,
    };
    Swal.fire(swalOptions).then((result) => {
      if (result.isConfirmed) {
        axiosHelper
          .delete(`api/Scheduler/Member/${memberSoid}/Weight/${poids.Soid}`)
          .then((result) => {
            const temp = { ...member };
            temp.Poids = result.data;
            setMember(temp);
          });
      }
    });
  };

  return (
    <Box>
      <TextField disabled value={moment(poids.Date).format("YYYY-MM-DD")} />
      <TextField
        type="number"
        inputProps={{
          step: ".5",
        }}
        value={weight}
        onChange={update}
      />
      <Button onClick={deletePoids}>
        <Delete></Delete>
      </Button>
    </Box>
  );
}
