import React, { useEffect } from "react";
import { AppBar, Toolbar, Typography, IconButton, Box, Button } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import { useTimer } from "react-timer-hook";
import { MoreTime, StopCircleOutlined } from "@mui/icons-material";
import useSound from "use-sound";
import NoSleep from "nosleep.js";

interface TimerProps {
  timerSeconds: number;
  isDisplayed: boolean;
  setIsTimerDisplayed: any;
  setTimerSeconds: any;
}

var noSleep = new NoSleep();

const Timer = ({
  timerSeconds,
  isDisplayed,
  setIsTimerDisplayed,
  setTimerSeconds,
}: TimerProps) => {
  const expiryTimestamp = new Date();
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + timerSeconds);

  const { seconds, minutes, isRunning, pause, resume, restart } = useTimer({
    expiryTimestamp,
    onExpire: () => {
      stop();
    },
  });

  useEffect(() => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + timerSeconds);
    restart(time);
  }, [timerSeconds, restart]);

  useEffect(() => {
    if (isDisplayed) noSleep.enable();
    else noSleep.disable();
  }, [isDisplayed]);

  const [playSound] = useSound("End.mp3", { volume: 1 });

  const stop = () => {
    if (isDisplayed) playSound();
    setTimerSeconds(1209813);
    setIsTimerDisplayed(false);
    pause();
  };

  const decreaseByFiveSeconds = () => {
    const newTime = Math.max(timerSeconds - 5, 0); // Ensure timer doesn't go negative
    setTimerSeconds(newTime);

    const newExpiryTimestamp = new Date();
    newExpiryTimestamp.setSeconds(newExpiryTimestamp.getSeconds() + newTime);
    restart(newExpiryTimestamp);
  };

  const toDoubleDigit = (n:number)  =>{
    return n.toString().length < 2 ? "0" + n : n.toString();
  }

  return isDisplayed ? (
    <AppBar position="fixed" color="primary" style={{ top: "auto", bottom: 0 }}>
      <Toolbar>
        <Button
          color="inherit"
          onClick={decreaseByFiveSeconds}
          startIcon={<MoreTime />}
        >
          -5 Sec
        </Button>
        <Box
          sx={{
            fontSize: "100px",
            flex: 1,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography variant="h3">
            {toDoubleDigit(minutes)}:{toDoubleDigit(seconds)}
          </Typography>
        </Box>
        {!isRunning && (
          <IconButton color="inherit" onClick={resume}>
            <PlayArrowIcon />
          </IconButton>
        )}
        {isRunning && (
          <IconButton color="inherit" onClick={pause}>
            <PauseIcon />
          </IconButton>
        )}
        <IconButton color="inherit" onClick={stop}>
          <StopCircleOutlined />
        </IconButton>
      </Toolbar>
    </AppBar>
  ) : null;
};

export default Timer;
