import React, { useContext, useEffect, useRef, useState } from "react";
import Swal, { SweetAlertOptions } from "sweetalert2";
import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Table,
  TableBody,
  Button,
  TableContainer,
  Paper,
} from "@mui/material";
import { debounce } from "@mui/material";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable as Droppable } from "../../../Common/Droppable";
import AxiosContext from "../../../../Data/AxiosContext";
import Routine from "./Routine";
import { IRoutine, ISession } from "../../../../Data/Member";
import { ArrowBack, ArrowForward, DeleteForever } from "@mui/icons-material";
import UserContext from "../../../../Data/UserContext";

export default function Session({
  session,
  setVideoUrl,
  setTabValue,
  isFirstSession,
  isLastSession,
}: {
  session: ISession;
  setVideoUrl: any;
  setTabValue: any;
  isFirstSession: boolean;
  isLastSession: boolean;
}) {
  const { member, setMember } = useContext(UserContext);
  const [routines, setRoutines] = useState(session.Routines);
  const [sessionName, setSessionName] = useState(session.Name);
  const { axiosHelper } = useContext(AxiosContext);

  useEffect(() => {
    const sessionRoutines = member?.Program?.Sessions?.find(
      (s) => s.Soid === session.Soid
    )?.Routines;
    if (sessionRoutines) setRoutines(sessionRoutines);
  }, [member, session.Soid]);

  const debouncedUpdate = useRef(
    debounce((val: any, fieldName: string) => {
      axiosHelper
        .post(
          `api/Scheduler/Member/${member.Soid}/Session/${
            session.Soid
          }/UpdateName/${encodeURIComponent(val)}`
        )
        .then(() => {
          const tempMember = { ...member };
          let sess = tempMember.Program.Sessions.find(
            (s) => s.Soid === session.Soid
          );
          if (sess != null) sess.Name = val;
          setMember(tempMember);
        });
    }, 1500)
  ).current;

  const deleteSession = () => {
    let swalOptions: SweetAlertOptions = {
      title: `T'es sur de vouloir supprimer cette session ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      scrollbarPadding: false,
    };
    Swal.fire(swalOptions).then((result) => {
      if (result.isConfirmed) {
        axiosHelper
          .delete(`api/Scheduler/Member/${member.Soid}/Session/${session.Soid}`)
          .then(() => {
            let tempMember = { ...member };
            tempMember.Program.Sessions = tempMember.Program.Sessions.filter(
              (obj: ISession) => obj.Soid !== session.Soid
            );
            setMember(tempMember);
            setTabValue((old: number) => {
              return old > 0 ? old - 1 : 0;
            });
          });
      }
    });
  };

  const handleChangeSessionName = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSessionName(event.target.value);
    debouncedUpdate(event.target.value as string, "UpdateName");
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;
    const oldRoutines: IRoutine[] = JSON.parse(JSON.stringify(routines));
    const updatedRoutines = [...routines];
    const [removed] = updatedRoutines.splice(result.source.index, 1);
    updatedRoutines.splice(result.destination.index, 0, removed);
    setRoutines(updatedRoutines);
    let data: string[] = [];
    updatedRoutines.forEach((r) => {
      data.push(r.Soid);
    });
    axiosHelper
      .patch(
        `api/Scheduler/Member/${member.Soid}/Session/${session.Soid}/UpdateRoutineOrder`,
        data
      )
      .then()
      .catch(() => setRoutines(oldRoutines));
  };

  const addRoutine = () => {
    axiosHelper
      .post(`api/Scheduler/Member/${member.Soid}/AddRoutine/${session.Soid}`)
      .then((result) => {
        let tempRoutines = [...routines];
        tempRoutines.push(result.data);
        setRoutines(tempRoutines);
      });
  };

  const deleteUserPart = () => {
    axiosHelper
      .delete(
        `api/Scheduler/Member/${member.Soid}/Session/${session.Soid}/DeleteUserData`
      )
      .then((result) => {
        const tempRoutines = [...result.data.Routines];
        setRoutines(tempRoutines);
      });
  };

  const moveSession = (position: "Left" | "Right") => {
    axiosHelper
      .post(
        `api/Scheduler/Member/${member.Soid}/Session/${session.Soid}/MoveTo/${position}`
      )
      .then((result) => {
        setMember((prev) => {
          if (prev) {
            const x = {...prev};
            x.Program = result.data;
            return x;
          } else {
            return prev;
          }
        });
      });
  };

  return (
    <Box style={{ paddingBottom: "60px" }}>
      <Box pb={2} textAlign="center">
        <TextField
          size="small"
          value={sessionName ?? ""}
          onChange={handleChangeSessionName}
          label="Nom de Session"
        />
        <Button
          sx={{ marginLeft: "10px" }}
          variant="outlined"
          color="error"
          onClick={deleteSession}
          startIcon={<DeleteForever />}
        >
          Supprimer Session
        </Button>
        {!isFirstSession && (
          <Button
            sx={{ marginLeft: "10px" }}
            variant="outlined"
            color="info"
            onClick={() => moveSession("Left")}
            startIcon={<ArrowBack />}
          >
            Déplacer
          </Button>
        )}
        {!isLastSession && (
          <Button
            sx={{ marginLeft: "10px" }}
            variant="outlined"
            color="info"
            onClick={() => moveSession("Right")}
            endIcon={<ArrowForward />}
          >
            Déplacer
          </Button>
        )}
      </Box>
      <TableContainer component={Paper} sx={{ margin: "10px" }}>
        <Table size="small">
          <TableHead sx={{ backgroundColor: "whitesmoke" }}>
            <TableRow>
              <TableCell />
              <TableCell>Exo</TableCell>
              <TableCell>Variante</TableCell>
              <TableCell>Par Coté</TableCell>
              <TableCell>
                <b>Reps</b>/Temps
              </TableCell>
              <TableCell>Series</TableCell>
              <TableCell>
                <b>Poids</b>/Distance/Reps
              </TableCell>
              <TableCell sx={{ textAlign: "center" }}>Recup</TableCell>
              <TableCell>Video</TableCell>
              <TableCell>Details</TableCell>
              <TableCell>Note</TableCell>
              <TableCell>DetailsU</TableCell>
              {/* <TableCell>Video</TableCell> */}
              <TableCell />
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="routines-list" direction="vertical">
              {(provided) => (
                <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                  {routines.map((routine, index) => (
                    <Draggable
                      key={routine.Soid} // Assuming routine.Soid is unique
                      draggableId={routine.Soid} // Use a prefix to ensure uniqueness
                      index={index}
                    >
                      {(provided) => (
                        <TableRow
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <Routine
                            dragHandleProps={provided.dragHandleProps}
                            routine={routine}
                            sessionSoid={session.Soid}
                            routines={routines}
                            setRoutines={setRoutines}
                            setVideoUrl={setVideoUrl}
                          />
                        </TableRow>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                  <TableRow>
                    <TableCell padding="none" colSpan={9} align="center">
                      <Button sx={{ width: 1 }} onClick={addRoutine}>
                        +
                      </Button>
                    </TableCell>
                    <TableCell
                      padding="none"
                      colSpan={5}
                      sx={{ borderLeft: "1px dotted grey" }}
                    >
                      <Button
                        sx={{ width: 1 }}
                        color={"warning"}
                        onClick={deleteUserPart}
                      >
                        x
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
    </Box>
  );
}
