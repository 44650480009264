import { useContext, useRef, useState } from "react";
import AssetContext from "../../../../Data/AssetsContext";
import {
  Button,
  Checkbox,
  TableCell,
  TableRow,
  TextField,
  debounce,
} from "@mui/material";
import { Exercice } from "../../../../Data/Assets";
import { Delete } from "@mui/icons-material";
import AxiosContext from "../../../../Data/AxiosContext";
import Swal, { SweetAlertOptions } from "sweetalert2";
import { typeOptions } from "./AddNewExercice";
import { unitOptions } from "./UnitTypeDropdown";
import Select from "react-select";

export default function EditExoRow({ exo }: { exo: Exercice }) {
  const { axiosHelper } = useContext(AxiosContext);
  const { exercices, setExercices } = useContext(AssetContext);
  const [name, setName] = useState(exo.Name ?? "");
  const [type, setType] = useState(exo.Type ?? "");
  const [url, setUrl] = useState(exo.Url ?? "");
  const [ytUrl, setYtUrl] = useState(exo.YoutubeUrl ?? "");
  const [target, setTarget] = useState(exo.Target ?? "");
  const [unitType, setUnitType] = useState(exo.UnitType ?? "");
  const [hasTimer, setHasTimer] = useState(exo.HasTimer);
  const debouncedUpdates = useRef<any>({}).current;

  const deleteExo = () => {
    let swalOptions: SweetAlertOptions = {
      title: `T'es sur de vouloir supprimer cet exercice ? Si l'exercice est attribué a un élève, ça peut causer des soucis !`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      scrollbarPadding: false,
    };
    Swal.fire(swalOptions).then((result) => {
      if (result.isConfirmed) {
        axiosHelper.delete(`api/Assets/Exercice/${exo.Soid}`).then((result) => {
          let tempExos = [...exercices];
          const index = tempExos.findIndex((i) => i.Soid === exo.Soid);
          tempExos.splice(index, 1);
          setExercices(tempExos);
        });
      }
    });
  };

  const getDebouncedUpdate = (fieldName: string) => {
    if (!debouncedUpdates[fieldName]) {
      debouncedUpdates[fieldName] = debounce((val: any) => {
        const data: any = {};
        data.FieldName = fieldName;
        data.Data = val;
        axiosHelper
          .post(`/api/Assets/Exercice/${exo.Soid}`, data)
          .then((result) => {
            let tempExos = [...exercices] as Exercice[];
            const exercice = tempExos.find((i) => i.Soid === exo.Soid);
            if (!exercice) return;
            switch (fieldName) {
              case "Name":
                exercice.Name = result.data.Data;
                break;
              case "Type":
                exercice.Type = result.data.Data;
                break;
              case "Url":
                exercice.Url = result.data.Data;
                break;
              case "YoutubeUrl":
                exercice.YoutubeUrl = result.data.Data;
                break;
              case "Target":
                exercice.Target = result.data.Data;
                break;
              case "UnitType":
                exercice.UnitType = result.data.Data;
                break;
              case "HasTimer":
                exercice.HasTimer = result.data.Data === "false" ? false : true;
                break;
            }
            setExercices(tempExos);
          });
      }, 800);
    }
    return debouncedUpdates[fieldName];
  };

  const updateField = (value: string, fieldName: string) => {
    switch (fieldName) {
      case "Name":
        setName(value);
        break;
      case "Type":
        setType(value);
        break;
      case "Url":
        setUrl(value);
        break;
      case "YoutubeUrl":
        setYtUrl(value);
        break;
      case "Target":
        setTarget(value);
        break;
      case "UnitType":
        setUnitType(value);
        break;
      case "HasTimer":
        setHasTimer(value === "true");
        break;
      default:
        break;
    }
    const debouncedUpdate = getDebouncedUpdate(fieldName);
    debouncedUpdate(value);
  };

  return (
    <TableRow>
      <TableCell>
        <TextField
          onChange={(e) => updateField(e.target.value, "Name")}
          value={name}
          fullWidth
          size="small"
        />
      </TableCell>
      <TableCell>
        <Select
          value={{ label: type, value: type }}
          options={typeOptions}
          onChange={(e) => updateField(e?.value ?? "", "Type")}
        />
      </TableCell>
      <TableCell>
        <TextField
          onChange={(e) => updateField(e.target.value, "Url")}
          value={url}
          fullWidth
          size="small"
        />
      </TableCell>
      <TableCell>
        <TextField
          onChange={(e) => updateField(e.target.value, "YoutubeUrl")}
          value={ytUrl}
          fullWidth
          size="small"
        />
      </TableCell>
      <TableCell>
        <TextField
          onChange={(e) => updateField(e.target.value, "Target")}
          value={target}
          fullWidth
          size="small"
        />
      </TableCell>
      <TableCell>
        <Select
          value={{ label: unitType, value: unitType }}
          options={unitOptions}
          onChange={(e) => updateField(e?.value ?? "", "UnitType")}
        />
      </TableCell>
      <TableCell>
        <Checkbox
          onChange={(e) => updateField(String(e.target.checked), "HasTimer")}
          checked={hasTimer}
        />
      </TableCell>
      <TableCell>
        <Button onClick={deleteExo}>
          <Delete />
        </Button>
      </TableCell>
    </TableRow>
  );
}
