import {
  Box,
  Divider,
  Grid,
  Paper,
  debounce,
  TextField,
  Typography,
  Fab,
} from "@mui/material";
import { isMobile } from "react-device-detect";
import { useContext, useRef, useState } from "react";
import AxiosContext from "../../Data/AxiosContext";
import UserContext from "../../Data/UserContext";
import { Done, Send } from "@mui/icons-material";
import { IProgram } from "../../Data/Member";

export default function Bilan() {
  const { member, setMember } = useContext(UserContext);
  const [weight, setWeight] = useState(String(member.Program.Weight));
  const [pain, setPain] = useState(member.Program.Pain);
  const [fun, setFun] = useState(member.Program.Fun);
  const [wishes, setWishes] = useState(member.Program.WishesForNextWeek);
  const [observations, setObservations] = useState(member.Program.Notes);
  const [isSent, setIsSent] = useState(false);
  const { axiosHelper } = useContext(AxiosContext);
  const debouncedUpdates = useRef<any>({}).current;

  const handleChange = (e: any) => {
    const name: string = e.target.name;
    const value: string = String(e.target.value);
    switch (name) {
      case "Pain":
        setPain(value);
        break;
      case "Fun":
        setFun(value);
        break;
      case "WishesForNextWeek":
        setWishes(value);
        break;
      case "Notes":
        setObservations(value);
        break;
      case "Weight":
        setWeight(String(value));
        break;
    }
    getDebouncedUpdate(name)(value);
  };

  const getDebouncedUpdate = (fieldName: string) => {
    if (!debouncedUpdates[fieldName]) {
      debouncedUpdates[fieldName] = debounce((val: any) => {
        updateField(val, fieldName);
      }, 500);
    }
    return debouncedUpdates[fieldName];
  };

  const submit = () => {
    axiosHelper.post(`api/Scheduler/Member/${member.Soid}/Bilan`).then(() => {
        const temp = { ...member };
        temp.BilanSent = true;
        setMember(temp);
      setIsSent(true);
    });
  };

  const updateField = (val: any, fieldName: string) => {
    const data: any = {};
    data.Data = val.toString();
    data.FieldName = fieldName;
    axiosHelper
      .patch(`api/Scheduler/Member/${member.Soid}/Bilan`, data)
      .then((result) => {
        const temp = { ...member };
        temp.Program = result.data as IProgram;
        if (setMember) setMember(temp);
      });
  };

  return (
    <Box>
      <Paper>
        <Box textAlign={"center"}>
          <Typography variant="h3" gutterBottom>
            Bilan de fin de semaine
          </Typography>
          <Box p={3}>
            <TextField
              name="Weight"
              label="Poids de la semaine"
              type="number"
              value={weight}
              onChange={handleChange}
            />
          </Box>
          <Divider />
          <Grid container pb="60px">
            <Grid item xs={12} md={6}>
              <Box p={1}>
                <TextField
                  name="Pain"
                  label="Douleurs Ressenties"
                  multiline
                  minRows={3}
                  value={pain}
                  onChange={handleChange}
                  fullWidth
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box p={1}>
                <TextField
                  name="Fun"
                  label="Plaisir Ressenti"
                  multiline
                  minRows={3}
                  value={fun}
                  onChange={handleChange}
                  fullWidth
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box p={1}>
                <TextField
                  name="WishesForNextWeek"
                  label="Souhaits (Semaine pro)"
                  multiline
                  minRows={3}
                  value={wishes}
                  onChange={handleChange}
                  fullWidth
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box p={1}>
                <TextField
                  name="Notes"
                  label="Observations diverses"
                  multiline
                  minRows={3}
                  value={observations}
                  onChange={handleChange}
                  fullWidth
                />
              </Box>
            </Grid>
          </Grid>
          <Fab
            disabled={isSent}
            onClick={submit}
            variant="extended"
            color="primary"
            sx={{
              position: "fixed",
              bottom: "70px",
              // If isMobile is true, use right: "20px"
              // If isMobile is false, use left: "50%" and transform: "translate(-50%, 0%)"
              ...(isMobile
                ? { right: "20px" }
                : { left: "50%", transform: "translate(-50%, 0%)" }),
            }}
          >
            {!isSent ? (
              <>
                <Send sx={{ mr: 1 }} />
                <Typography sx={{ fontWeight: "bold" }}>
                  JE VALIDE MA SEMAINE
                </Typography>
              </>
            ) : (
              <Done />
            )}
          </Fab>
          {/* <Button
            disabled={isSent}
            onClick={submit}
            variant="contained"
            sx={{ width: "100%" }}
          >
            {!isSent ? (
              <Typography sx={{ fontWeight: "bold" }}>
                JE VALIDE MA SEMAINE
              </Typography>
            ) : (
              <Done />
            )}
          </Button> */}
        </Box>
      </Paper>
    </Box>
  );
}
