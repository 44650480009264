import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  TableFooter,
  Button,
} from "@mui/material";
import { useContext } from "react";
import UserContext from "../../../../Data/UserContext";
import AxiosContext from "../../../../Data/AxiosContext";
import FoodComplementRow from "./FoodComplementRow";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable as Droppable } from "../../../Common/Droppable";
import { IFoodSupplement } from "../../../../Data/Member";

export default function FoodComplements({ isVisible }: { isVisible: boolean }) {
  const { member, setMember } = useContext(UserContext);
  const { axiosHelper } = useContext(AxiosContext);

  const addFoodSupplement = () => {
    axiosHelper
      .post(`/api/Scheduler/Member/${member.Soid}/FoodSupplement`)
      .then((result) => {
        const temp = { ...member };
        temp.Program.FoodSupplements = result.data;
        setMember(temp);
      });
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) {
      console.log("failed");
      return;
    }

    const oldSupplements: IFoodSupplement[] = JSON.parse(
      JSON.stringify(member.Program.FoodSupplements)
    );
    const updatedSupplements = [...member.Program.FoodSupplements];
    const [removed] = updatedSupplements.splice(result.source.index, 1);
    updatedSupplements.splice(result.destination.index, 0, removed);
    setMember((prev) => {
      if (prev) {
        const temp = { ...prev };
        temp.Program.FoodSupplements = updatedSupplements;
        return temp;
      } else {
        return null;
      }
    });
    let data: string[] = [];
    updatedSupplements.forEach((s) => {
      data.push(s.Soid);
    });
    const url = `/api/Scheduler/Member/${member.Soid}/UpdateComplementOrder`;
    axiosHelper.patch(url, data).then()
    .catch(() =>     setMember((prev) => {
      if (prev) {
        const temp = { ...prev };
        temp.Program.FoodSupplements = oldSupplements;
        return temp;
      } else {
        return null;
      }
    }));
  };

  return (
    <Box
      sx={{
        display: isVisible ? "block" : "none",
      }}
    >
      <TableContainer
        sx={{
          margin: "10px",
        }}
      >
        <Table size="small">
          <TableHead
            sx={{
              backgroundColor: "lightblue",
            }}
          >
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="center">Aliment</TableCell>
              <TableCell align="center">Quantité</TableCell>
              <TableCell align="center">Supprimer</TableCell>
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="complement-list" direction="vertical">
              {(provided) => (
                <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                  {member.Program.FoodSupplements.map((item, index) => (
                    <Draggable
                      key={item.Soid}
                      draggableId={item.Soid}
                      index={index}
                    >
                      {(provided) => (
                        <TableRow
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <FoodComplementRow
                            dragHandleProps={provided.dragHandleProps}
                            item={item}
                            key={item.Soid}
                          />
                        </TableRow>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>

          <TableFooter>
            <TableRow>
              <TableCell
                colSpan={3}
                sx={{
                  textAlign: "center",
                }}
                padding="none"
              >
                <Button
                  size="small"
                  sx={{
                    width: 1,
                  }}
                  onClick={addFoodSupplement}
                >
                  +
                </Button>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
}
