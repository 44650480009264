import {
  Paper,
  Box,
  Typography,
  TextField,
  Divider,
  Grid,
  Button,
  Container,
} from "@mui/material";
import { useContext, useState } from "react";
import EditPoids from "./EditPoids";
import UserContext from "../../../Data/UserContext";
import { IQuestion, IUser } from "../../../Data/Member";
import AxiosContext from "../../../Data/AxiosContext";
import { Check, Done } from "@mui/icons-material";
import Swal from "sweetalert2";

export default function Edit() {
  const { axiosHelper } = useContext(AxiosContext);
  const { member, setMember } = useContext(UserContext);
  const [firstName, setFirstName] = useState(member.FirstName);
  const [lastName, setLastName] = useState(member.LastName);
  const [userName, setUserName] = useState(member.UserName);
  const [password, setPassword] = useState("");
  const [notes, setNotes] = useState(member.Notes);
  const [birthDate, setBirthDate] = useState(member.BirthDate ?? "");
  const [emailAddress, setEmailAddress] = useState(member.EmailAddress);
  const [isSent, setIsSent] = useState(false);
  const [newPoids, setNewPoids] = useState("")
  const [newPoidsDate, setNewPoidsDate] = useState("")

  const editMember = () => {
    const temp: IUser = {} as IUser;
    temp.FirstName = firstName;
    temp.LastName = lastName;
    temp.UserName = userName;
    temp.Password = password;
    temp.EmailAddress = emailAddress;
    temp.Notes = notes;
    temp.BirthDate = birthDate;
    axiosHelper
      .patch(`api/Scheduler/Member/${member.Soid}/EditBaseMember`, temp)
      .then(() => {
        setIsSent(true);
      })
      .catch((error) => {
        Swal.fire({
          title: "Erreur!",
          text: error.response.data,
          icon: "error",
        });
      });
  };

  const addNewPoids = () => {
    const data:any = {};
    data.Date = newPoidsDate;
    data.Poids = newPoids;
    axiosHelper
      .post(`api/Scheduler/Member/${member.Soid}/AddNewPoids`, data)
      .then(result => {
        const temp = {...member};
        temp.Poids = result.data;
        setMember(temp);
        setNewPoids("")
        setNewPoidsDate("")
      })
  }
  //const alphanumericDashRegex = /^[a-zA-Z0-9-]+$/;
  //const handleUserNameChange = (e: any) => {
  //  const inputValue = e.target.value;
  //  if (inputValue === "" || alphanumericDashRegex.test(inputValue)) {
  //    setUserName(inputValue);
  //  }
  //};


  return (
    <Container>
      <Paper>
        <Box >
          <Typography variant="h3" gutterBottom>
            {member.FirstName + " " + member.LastName}
          </Typography>
          <Divider />
          {/* Informations */}
          <Typography variant="h4" gutterBottom>
            Informations
          </Typography>
          <Grid container spacing={2}>
            <Grid item sm={3} xs={12}>
              <TextField
                sx={{ m: 1 }} // add margin
                label="Prénom"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <TextField
                sx={{ m: 1 }} // add margin
                label="Nom"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <TextField
                sx={{ m: 1 }} // add margin
                label="Pseudo"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                //onChange={handleUserNameChange}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <TextField
                sx={{ m: 1 }} // add margin
                label="Mot de passe"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                sx={{ m: 1 }} // add margin
                label="Email"
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                sx={{ m: 1 }} // add margin
                label="Date de Naissance"
                value={birthDate}
                onChange={(e) => setBirthDate(e.target.value)}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                sx={{ m: 1 }}
                label="Notes"
                value={notes}
                multiline
                onChange={(e) => setNotes(e.target.value)}
              />
            </Grid>
          </Grid>
          <Button disabled={isSent} onClick={editMember} variant="contained">
            {!isSent ? "Envoyer" : <Done />}
          </Button>
          <Divider sx={{ paddingTop: "5px" }} />
          <Box>
            <Typography variant="h4" gutterBottom>
              Notes
            </Typography>
          </Box>
          <Divider sx={{ paddingTop: "5px" }} />
          <Typography variant="h4" gutterBottom>
            Poids
          </Typography>
          {member.Poids.slice(-10).map((poids) => (
            <EditPoids
              key={poids.Soid}
              poids={poids}
              memberSoid={member.Soid}
            />
          ))}
          <Box>
            <TextField
              type="date"
              value={newPoidsDate}
              onChange={(e) => setNewPoidsDate(e.target.value)}
            />
            <TextField
              type="number"
              inputProps={{
                step: ".5",
              }}
              value={newPoids}
              onChange={(e) => setNewPoids(e.target.value)}
            />
            <Button onClick={addNewPoids}>
              <Check />
            </Button>
          </Box>
          <Divider />
          {/* Questionnaire */}
          <Typography variant="h4" gutterBottom>
            Questionnaire
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <QuestionBlock title="Etudes" answer={member.Survey.Etudes} />
            <QuestionBlock title="Parcours" answer={member.Survey.Parcours} />
            <QuestionBlock title="Travail" answer={member.Survey.Travail} />
            <QuestionBlock
              title="Taille"
              answer={member.Survey.Taille.toString()}
            />
            <QuestionGroup
              title="Questions (Sport)"
              questions={member.Survey.Questions}
            />
            <QuestionGroup
              title="Questions (Alimentation)"
              questions={member.Survey.FoodQuestions}
            />
          </Box>
        </Box>
      </Paper>
    </Container>
  );
}

const QuestionBlock = ({
  title,
  answer,
}: {
  title: string;
  answer: string;
}) => (
  <Box>
    <Typography variant="h5">{title}</Typography>
    <Typography>{answer}</Typography>
  </Box>
);

const QuestionGroup = ({
  title,
  questions,
}: {
  title: string;
  questions: IQuestion[];
}) => (
  <Box m={1}>
    <Paper sx={{ backgroundColor: "whitesmoke", padding: "10px" }}>
      <Typography variant="h5" sx={{ textDecoration: "underline" }}>
        {title}
      </Typography>
      {questions.map((q, index) => (
        <Box key={index}>
          <Typography
            variant="body1"
            sx={{ fontStyle: "italic" }}
            color={"gray"}
          >
            {q.Quest}
          </Typography>
          <Typography>{q.Reponse}</Typography>
        </Box>
      ))}
    </Paper>
  </Box>
);
