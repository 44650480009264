import { useContext, useState } from "react";
import Container from "@mui/material/Container";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import DescriptionIcon from "@mui/icons-material/Description";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import Paper from "@mui/material/Paper";
import Entrainements from "./Entrainement/Entrainements";
import Bilan from "./Bilan";
import UserContext from "../../Data/UserContext";
import { Box } from "@mui/material";
import Meals from "./Repas/Meals";

export function MainApplication() {
  const [value, setValue] = useState(
    window.location.hash.substr(1) || "Entrainement"
  );
  const { member } = useContext(UserContext);
  const handleNavigationChange = (
    _: React.ChangeEvent<{}>,
    newValue: string
  ) => {
    window.location.hash = newValue;
    setValue(newValue);
  };

  return (
    <Container>
      <Box
        sx={{
          paddingBottom: "60px",
        }}
      >
        {value === "Entrainement" && <Entrainements />}
        {value === "Repas" && <Meals />}
        {value === "Bilan" && <Bilan />}
      </Box>
      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
        }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={value}
          onChange={handleNavigationChange}
        >
          <BottomNavigationAction
            value="Entrainement"
            label="Entrainement"
            icon={<FitnessCenterIcon />} />
          <BottomNavigationAction
            value="Repas"
            label="Repas"
            icon={<RestaurantIcon />} />
          <BottomNavigationAction
            value="Bilan"
            label="Bilan"
            icon={<DescriptionIcon color={member.BilanSent ? undefined : "error"} />} />
        </BottomNavigation>
      </Paper>
    </Container>
  );
}
