import { useContext, useState } from "react";
import Box from "@mui/material/Box";
import UserContext from "../../../Data/UserContext";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { SessionAccordion } from "./SessionAccordion";
import { ISession } from "../../../Data/Member";

function a11yProps(soid: string) {
  return {
    id: `simple-tab-${soid}`,
    "aria-controls": `simple-tabpanel-${soid}`,
  };
}

export default function Entrainements() {
  const { member } = useContext(UserContext);
  const findDefaultSession = (sessions: ISession[]) => {
    for (let session of sessions) {
      const routineWithoutNote = session.Routines.find(
        (routine) =>
          routine.Note === 0
      );
      if (routineWithoutNote) {
        return session.Soid;
      }
    }
    return sessions[sessions.length - 1].Soid;
  };

  const [value, setValue] = useState(
    findDefaultSession(member.Program.Sessions)
  );
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box display="flex" justifyContent="center" mb={1}>
        <Tabs value={value} onChange={handleChange} variant="scrollable">
          {member.Program.Sessions &&
            member.Program.Sessions.map((session) => (
              <Tab
                key={session.Soid}
                label={session.Name}
                value={session.Soid}
                {...a11yProps(session.Soid)}
              />
            ))}
        </Tabs>
      </Box>
      {member.Program.Sessions &&
        member.Program.Sessions.map((session) => (
          <SessionAccordion
            key={"Tab-" + session.Soid}
            routines={session.Routines}
            sessionSoid={session.Soid}
            value={value}
          />
        ))}
    </Box>
  );
}
